import React, { ChangeEvent, createRef, FC, FocusEvent, InputHTMLAttributes, RefObject } from 'react';

import { Icon } from '../identity/icon';
import { Label } from '../identity/typography/label';
import { fontDeclaration, TypoWeights } from '../identity/typography/typography';
import styled from '../utils/styled-components';

type BorderRadius = {
  left: boolean;
  right: boolean;
};

const Container = styled.div<{ withRadius?: BorderRadius }>`
  position: relative;
  display: inline-flex;
  fill: ${({ theme: { colors } }) => colors.grey[25]};
  border: 1px solid ${({ theme: { colors } }) => `${colors.grey[25]}`};
  border-radius: ${({ withRadius }) =>
    withRadius
      ? `${withRadius.left ? '3px' : '0px'} ${withRadius.right ? '3px 3px' : '0px 0px'} ${withRadius.left ? '3px' : '0px'}`
      : '0px'};

  background: ${({ theme: { colors } }) => `${colors.grey[25]}`};
  width: 100%;
`;

export type InputProps = {
  onChangeText?: (text: string) => void;
  onRemoveText?: () => void;
  onShowCategories?: () => void;
  filter?: string;
  emphasis?: boolean;
  onIconClick?: () => void;
  ref?: RefObject<HTMLInputElement> | ((instance: any) => void) | null;
  withRadius?: BorderRadius;
};

const StyledInput = styled.input<InputProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  width: 100%;
  ${fontDeclaration};
  font-size: ${({ emphasis }) => (emphasis ? '1rem' : '0.875rem')};
  font-weight: ${({ emphasis }) => (emphasis ? TypoWeights.medium : TypoWeights.regular)};
  background: ${({ theme: { colors } }) => `${colors.grey[25]}`};
  border: none;
  color: ${({ theme: { colors } }) => `${colors.grey[125]}`};
  outline: none;
  margin: 0;
  border-radius: ${({ withRadius }) =>
    withRadius
      ? `${withRadius.left ? '3px' : '0px'} ${withRadius.right ? '3px 3px' : '0px 0px'} ${withRadius.left ? '3px' : '0px'}`
      : '0px'};
  padding: ${({ theme: { spacing } }) => `${spacing.lg} ${spacing.xl}`};

  &::placeholder {
    color: ${({ theme: { colors } }) => colors.grey[50]};
  }
`;

export const Input: FC<InputProps & InputHTMLAttributes<HTMLInputElement>> = React.forwardRef(
  (
    {
      onIconClick,
      onRemoveText,
      onBlur,
      onChangeText = () => {},
      onShowCategories,
      withRadius,
      filter,
      ...props
    }: InputProps & InputHTMLAttributes<HTMLInputElement>,
    ref,
  ) => {
    const buttonRef = createRef<HTMLButtonElement>();
    const { value } = props;

    return (
      <Container withRadius={withRadius}>
        <StyledInput
          {...props}
          ref={ref}
          onBlur={(event: FocusEvent<HTMLInputElement>) => {
            const isIconClick = buttonRef.current !== null && event.relatedTarget === buttonRef.current;
            if (onBlur && !isIconClick) {
              onBlur(event);
            }
          }}
          onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => onChangeText(value)}
          withRadius={withRadius}
        />
        {onRemoveText && value && (
          <IconContainer onClick={onRemoveText}>
            <Icon name="times" size="medium" />
          </IconContainer>
        )}
        {onShowCategories && (
          <CategoryContainer onClick={onShowCategories}>
            <Icon name="chevron-down" size="medium" />
            {filter && <FilterLabel>{filter}</FilterLabel>}
          </CategoryContainer>
        )}
      </Container>
    );
  },
);

export const FilterLabel = styled(Label)`
  display: block;
  overflow: hidden;
  @media only screen and (max-width: 500px) {
    max-width: 55px;
  }
  font-weight: ${TypoWeights.medium};
  white-space: nowrap;
  color: ${({ theme: { colors } }) => `${colors.grey[125]}`};
  text-overflow: ellipsis;
  font-size: 0.8rem;
  padding: ${({ theme: { spacing } }) => `0 ${spacing.sm} 0  ${spacing.md}`};
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  background: ${({ theme: { colors } }) => `${colors.grey[25]}`};
  cursor: pointer;
`;

const CategoryContainer = styled(IconContainer)`
  background: ${({ theme: { colors } }) => `${colors.primary.white}`};
`;
