import React, { ButtonHTMLAttributes, FC, MouseEvent, ReactNode } from 'react';

import { Label } from '../identity/typography/label';
import { TypoVariations } from '../identity/typography/typography';
import styled from '../utils/styled-components';

export type ButtonProps = {
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  wide?: boolean;
  inverse?: boolean;
};

const BaseButton = styled.button<ButtonProps>`
  background: ${({ theme: { colors }, inverse }) => (inverse ? colors.primary.white : colors.brandcolor[100])};
  border: none;
  border-radius: 3px;
  padding: ${({ theme: { spacing } }) => `${spacing.xl} ${spacing.xxl}`};
  text-align: center;
  width: ${({ wide }) => (wide ? '100%' : 'auto')};
  cursor: pointer;
  transition: background 0.25s ease-in-out, box-shadow 0.25s ease-in-out, transform 0.25s ease-in-out;
  outline: none;
  user-select: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  a:hover {
    background: ${({ theme: { colors }, inverse }) => (inverse ? colors.grey[50] : colors.brandcolor[200])};
    outline: 0;
    cursor: pointer;
    transform: scale(0.98);
  }

  @media (hover: none) {
    a:hover {
      background: ${({ theme: { colors }, inverse }) => (inverse ? colors.primary.white : colors.brandcolor[100])};
      outline: 0;
      cursor: inherit;
      transform: scale(1);
      transition: none;
    }
  }
`;

export const Button: FC<ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  wide = false,
  onClick,
  disabled,
  inverse,
}) => (
  <BaseButton wide={wide} onClick={onClick} disabled={disabled} inverse={inverse}>
    <Label variation={inverse ? TypoVariations.accent : TypoVariations.inverse}>{children}</Label>
  </BaseButton>
);
