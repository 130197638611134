import React, { FC } from 'react';
import styled from 'styled-components';

import { Button } from '../elements/button';
import { PageTitle } from '../identity/typography/title';
import { Modal, ModalContentItem } from '../layout/modal';

export type Status = 'error' | 'success' | 'pending';

type AlertProps = { type: Status };

export const Alert = styled.span<AlertProps>`
  display: block;
  text-align: center;
  padding-bottom: 1.5rem;
  color: ${({ theme: { colors }, type }) =>
    ({
      error: colors.alarm[100],
      success: colors.brandcolor[200],
      pending: colors.grey[75],
    }[type])};
`;

const FormElement = styled.form`
  width: 100%;
`;

export type FormProps = {
  status?: Status;
  message?: string;
  onSubmit: () => any;
  submitLabel?: string;
  title?: string;
};

export const Form: FC<FormProps> = ({ title, children, status, message, submitLabel, onSubmit }) => (
  <FormElement
    onSubmit={e => {
      e.preventDefault();
      onSubmit();
    }}
  >
    <Modal>
      <ModalContentItem>
        <PageTitle>{title}</PageTitle>
      </ModalContentItem>
      {children}
      {status && (
        <ModalContentItem>
          <Alert type={status}>{message}</Alert>
        </ModalContentItem>
      )}
      <ModalContentItem>
        <Button type="submit" disabled={status === 'pending'} aria-label={submitLabel}>
          {submitLabel}
        </Button>
      </ModalContentItem>
    </Modal>
  </FormElement>
);
