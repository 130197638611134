const sanitizeHtml = require('sanitize-html');

export function sanitize(str?: string): string {
  const allowedTags = ['b', 'i', 'em', 'strong', 'a', 'br'];
  const allowedAttributes = {
    a: ['href', 'target'],
  };
  const allowedSchemes = ['skype', 'http', 'whatsapp', 'slack', 'https', 'mailto'];

  return sanitizeHtml(str || '', { allowedTags, allowedAttributes, allowedSchemes }) as string;
}
