import React, { ReactNode } from 'react';
import { keyframes } from 'styled-components';

import styled from '../utils/styled-components';
import { theme } from '../utils/theme';

export default class Sticky extends React.Component<{ children: ReactNode }, StickyState> {
  constructor(props: { children: ReactNode }) {
    super(props);

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      initial: true,
    };
  }

  public componentDidMount(): void {
    window.addEventListener('scroll', this.handleScroll);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }

  public handleScroll = (): void => {
    const { prevScrollpos } = this.state;
    const currentScrollPos = window.pageYOffset;
    let visible = false;
    let initial = this.state.initial;

    if (currentScrollPos > 100) {
      initial = false;
      visible = prevScrollpos > currentScrollPos;
    } else {
      initial = true;
      visible = false;
    }

    this.setState({
      visible,
      initial,
      prevScrollpos: currentScrollPos,
    });
  };
  public render(): ReactNode {
    const { visible, initial } = this.state;
    const { children } = this.props;

    return (
      <StickyContainer visible={visible} initial={initial}>
        {children}
      </StickyContainer>
    );
  }
}

interface StickyState extends StickyContainerProps {
  prevScrollpos: number;
}

interface StickyContainerProps {
  visible: boolean;
  initial: boolean;
}

const StickyContainer = styled.div<StickyContainerProps>`
  width: 100%;
  top: ${({ visible, initial, theme }) => (initial || visible ? '0px' : `-${theme.headerHeight}`)};
  animation: ${({ visible, initial }) => !initial && (visible ? appear : disappear)} 0.3s ease-in-out 1;
  position: fixed;
`;

const appear = keyframes`
  from { top:-${theme.headerHeight} }
  to { top:0px }
`;

const disappear = keyframes`
  from { top:0px }
  to { top:-${theme.headerHeight}; }
`;
