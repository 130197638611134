export type Color = {
  primary: {
    brandcolor: string;
    black: string;
    white: string;
  };
  grey: {
    10: string;
    25: string;
    50: string;
    75: string;
    100: string;
    125: string;
  };
  brandcolor: {
    100: string;
    200: string;
  };
  alarm: {
    100: string;
  };
};

export const defaultColors: Color = {
  primary: { brandcolor: '#696969', black: '#000000', white: '#FFFFFF' },
  grey: { 10: '#F5F5F5', 25: '#EEEEEE', 50: '#C0C0C0', 75: '#969696', 100: '#696969', 125: '#484848' },
  brandcolor: {
    100: '#696969',
    200: '#696969',
  },
  alarm: { 100: '#FF7272' },
};

export const colors: Color = defaultColors;
