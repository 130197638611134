import React, { useContext } from 'react';

import { Button } from '../elements/button';
import { Link } from '../elements/link';
import { Icon } from '../identity/icon';
import { Prose } from '../identity/typography/prose';
import { PageTitle } from '../identity/typography/title';
import { TypoVariations } from '../identity/typography/typography';
import { AppContext } from '../utils/app-context';
import styled from '../utils/styled-components';

export const Joyride = () => {
  const { current } = useContext(AppContext);
  const {
    context: { i18n, lang },
    matches,
  } = current;

  return (
    <>
      {matches({ Joyride: 'StartJoyride' }) ? (
        <JoyrideItem text={i18n[lang].joyride_start_content} title={i18n[lang].joyride_start_title} centerContent next />
      ) : matches({ Joyride: 'SearchBar' }) ? (
        <JoyrideItem text={i18n[lang].joyride_one_content} centerHeader previous next withPointer left="60px" top="40px" />
      ) : matches({ Joyride: 'Categories' }) ? (
        <JoyrideItem text={i18n[lang].joyride_two_content} centerHeader previous next withPointer right="50px" top="40px" />
      ) : matches({ Joyride: 'Menu' }) ? (
        <JoyrideItem text={i18n[lang].joyride_three_content} previous next withPointer right="15px" top="40px" />
      ) : matches({ Joyride: 'ContentItem' }) ? (
        <JoyrideItem text={i18n[lang].joyride_four_content} centerContent previous next withPointer left="50px" top="90px" />
      ) : matches({ Joyride: 'More' }) ? (
        <JoyrideItem
          text={i18n[lang].joyride_five_content}
          centerContent
          previous
          next
          withPointer
          right="40px"
          top="220px"
        />
      ) : matches({ Joyride: 'ScrollDown' }) ? (
        <JoyrideItem text={i18n[lang].joyride_six_content} centerContent previous next right="50px" top="220px" />
      ) : matches({ Joyride: 'BackToTop' }) ? (
        <JoyrideItem text={i18n[lang].joyride_seven_content} previous next withPointer right="27px" bottom="130px" />
      ) : matches({ Joyride: 'OurLogo' }) ? (
        <JoyrideItem text={i18n[lang].joyride_eight_content} previous next withPointer left="20px" top="40px" />
      ) : matches({ Joyride: 'GoodToGo' }) ? (
        <JoyrideItem text={i18n[lang].joyride_nine_content} previous close />
      ) : (
        <div />
      )}
    </>
  );
};

enum TransitionType {
  next = 'NEXT',
  previous = 'PREVIOUS',
  close = 'CLOSE',
}

const Next = () => <JoyrideButton type={TransitionType.next} />;
const Close = () => <JoyrideButton type={TransitionType.close} />;
const Previous = () => <JoyrideButton type={TransitionType.previous} />;

type JoyrideItemProps = {
  text?: string;
  title?: string;
  centerHeader?: boolean;
  centerContent?: boolean;
  next?: boolean;
  previous?: boolean;
  close?: boolean;
  withPointer?: boolean;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
};

const JoyrideItem = ({
  text,
  title,
  centerHeader,
  centerContent,
  next,
  previous,
  close,
  withPointer,
  top,
  right,
  bottom,
  left,
}: JoyrideItemProps) => (
  <JoyrideContainer>
    <JoyrideCenter centerContent={centerContent} centerHeader={centerHeader}>
      <PointerPosition top={top} right={right} bottom={bottom} left={left}>
        {withPointer && <JoyridePointer top={!!top} bottom={!!bottom} />}
        <JoyrideContent withPosition={!!top || !!right || !!bottom || !!left}>
          <CloseIconButton />
          {title && <PageTitle variation={TypoVariations.inverse}>{title}</PageTitle>}
          <Prose variation={TypoVariations.inverse}>{text}</Prose>
          <NextPreviousContainer>
            {previous && <Previous />}
            {!previous && <div />}
            {next && <Next />}
            {close && <Close />}
          </NextPreviousContainer>
        </JoyrideContent>
      </PointerPosition>
    </JoyrideCenter>
  </JoyrideContainer>
);

const JoyrideButton = ({ type }: { type: TransitionType }) => {
  const { current, transition } = useContext(AppContext);
  const {
    context: { i18n, lang },
  } = current;

  return (
    <JoyrideButtonContainer>
      <Button inverse onClick={() => transition(type)}>
        {i18n[lang][`joyride_${type.toLowerCase()}`]}
      </Button>
    </JoyrideButtonContainer>
  );
};

const CloseIconButton = () => {
  const { transition } = useContext(AppContext);
  return (
    <CloseContainer>
      <CloseLink onClick={() => transition('CLOSE')}>
        <Icon name="times" variation="white" />
      </CloseLink>
    </CloseContainer>
  );
};

const JoyridePointer = styled.div<{ top: boolean | undefined; bottom: boolean | undefined }>`
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  ${({ bottom, theme }) => (bottom ? `border-top: 14px solid ${theme.colors.grey[10]}` : '')};
  ${({ top, theme }) => (top ? `border-bottom: 14px solid ${theme.colors.grey[10]}` : '')};
  margin: 0 6px;
  background: rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 20px 20px rgba(0, 0, 0, 0.15);
`;

const CloseContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: ${({ theme: { spacing } }) => spacing.xxl};
`;

const NextPreviousContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ theme: { spacing } }) => spacing.xxl};
`;

const CloseLink = styled(Link)`
  padding: 0;
`;

const JoyrideButtonContainer = styled.div`
  margin-right: ${({ theme: { spacing } }) => spacing.lg};
  display: inline-block;
`;

const JoyrideContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  color: ${({ theme }) => theme.colors.primary.white};
  z-index: 10000;
`;

const JoyrideCenter = styled.div<{ centerContent?: boolean; centerHeader?: boolean }>`
  ${({ centerContent, centerHeader, theme: { contentWidth, headerHeight } }) =>
    centerHeader
      ? `
        width: 100%;
        max-width: calc(${contentWidth['wide']} + 2 * ${headerHeight});
        margin: auto;
      `
      : centerContent
      ? ` width: 100%;
        max-width: calc(${contentWidth['wide']});
        margin: auto;`
      : ''};

  height: 100%;
`;

const JoyrideContent = styled.div<{ withPosition?: boolean }>`
  position: relative;
  padding: ${({ theme: { spacing } }) => `${spacing.lg} ${spacing.xl} ${spacing.xxl} ${spacing.xxl}`};
  ${({ withPosition }) => (withPosition ? `margin: 0;` : 'margin: 200px auto 0;')}
  border-radius: ${({ theme: { spacing } }) => spacing.lg};;
  max-width: 450px;
  color: ${({ theme }) => theme.colors.primary.white};
  background: rgba(0, 0, 0, 0.65);
  border: solid 3px ${({ theme }) => theme.colors.grey[10]};

  @media only screen and (max-width: 500px) {
    ${({ withPosition, theme: { spacing } }) => (withPosition ? `margin: ${spacing.lg} 0;` : 'margin: 100px auto 0;')}
    max-width: 65vw;
  }
`;

const PointerPosition = styled.div<{ left?: string; top?: string; right?: string; bottom?: string }>`
  ${({ left, top, right, bottom }) =>
    left || top || right || bottom
      ? `
        display: flex;
        align-items: ${right ? 'flex-end' : 'flex-start'};
        justify-content: ${bottom ? 'end' : 'flex-start'};
        flex-direction: ${bottom ? 'column-reverse' : 'column'};
        position: relative;
        height: 100%;
        color: blue;
        ${left ? `left: ${left}` : ''};
        ${right ? `right: ${right}` : ''};
        ${bottom ? `bottom: ${bottom}` : ''};
        ${top ? `top: ${top}` : ''};
      `
      : ''};
`;
