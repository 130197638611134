import React, { FC, ReactChild } from 'react';
import * as styledComponents from 'styled-components';

import { Color } from '../identity/colors';
import { Theme, theme } from './theme';

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
}: styledComponents.ThemedStyledComponentsModule<Theme> = styledComponents;

export const HRAppThemeProvider: FC<{
  children: ReactChild;
  colors: Color;
}> = ({ children, colors }) => {
  return <ThemeProvider theme={{ ...theme, colors: colors ? colors : theme.colors }}>{children}</ThemeProvider>;
};

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
