import styled from '../../utils/styled-components';
import { fontDeclaration, TypoVariations, TypoVariationsMap, TypoWeights } from './typography';

type TitleProps = {
  variation?: TypoVariations;
};

export const PageTitle = styled.h1<TitleProps>`
  ${fontDeclaration};
  font-size: 1.563em;
  line-height: 1.25rem;
  font-weight: ${TypoWeights.bold};
  color: ${({ variation = TypoVariations.primary }) => TypoVariationsMap[variation]};
  margin: 0.25em 0 0.64em;
`;

export const SecondaryTitle = styled.h3<TitleProps>`
  ${fontDeclaration};
  font-size: 1rem;
  line-height: 1.125rem;

  font-weight: ${TypoWeights.bold};
  color: ${({ variation = TypoVariations.primary }) => TypoVariationsMap[variation]};
  margin: 0.25rem 0;
`;
