import styled from '../utils/styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  flex: 1;
  box-sizing: border-box;
  background: white;
  margin: ${({ theme: { spacing } }) => `${spacing.lg} ${spacing.xl}`};
  padding: ${({ theme: { spacing } }) => `${spacing.lg} 0`};
`;

export const ModalContentItem = styled.div`
  flex: 1;
  box-sizing: border-box;
  background: white;
  margin: ${({ theme: { spacing } }) => `${spacing.xl} ${spacing.xxl}`};
`;
