import React, { FC, useContext } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { FilterLabel, Input } from '../elements/input';
import { Icon } from '../identity/icon';
import Sticky from '../layout/sticky';
import { AppContext } from '../utils/app-context';
import styled from '../utils/styled-components';
import MainNavigation from './main-navigation';

const HeaderContainer = styled.header`
  width: 100%;
  height: ${({ theme: { headerHeight } }) => headerHeight};
  background: ${({ theme: { colors } }) => colors.brandcolor[200]};
`;

const HeaderBar = styled.div`
  display: flex;
`;

type HeaderBarItemProps = {
  wide?: boolean;
};

const HeaderBarItem = styled.div<HeaderBarItemProps>`
  padding: ${({ theme: { spacing }, wide }) => (wide ? '0' : `0 ${spacing.sm}`)};
  align-self: center;
  ${({ wide, theme }) => (wide ? 'flex: 1' : `width:${theme.headerHeight};`)};
`;

type HeaderItemProps = {
  withSearch?: boolean;
  filter?: string[];
};

const HeaderWrapper = styled.div`
  height: ${({ theme: { headerHeight } }) => headerHeight};
  position: relative;
  z-index: 10;
`;

export const EmptyHeader: FC = () => (
  <HeaderWrapper>
    <HeaderContainer />
  </HeaderWrapper>
);

export const Header: FC<HeaderItemProps> = ({ withSearch, filter }) => {
  const { current, transition: send } = useContext(AppContext);
  const {
    matches,
    context: { i18n, lang, searchTerm, categoryFilter },
  } = current;

  if (!i18n[lang]) {
    return <div />;
  }
  return (
    <HeaderWrapper>
      <Sticky>
        <HeaderContainer>
          <HeaderBar>
            <HeaderBarItem>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  send('HOME');
                }}
                aria-label={i18n[lang].home}
              >
                <img src="/assets/android-chrome-192x192.png" width="50px" height="50px" alt="Home" />
              </a>
            </HeaderBarItem>
            <HeaderBarItem wide>
              {withSearch && (
                <SearchContainer>
                  <Input
                    placeholder={i18n[lang].search}
                    value={searchTerm}
                    onChangeText={searchTerm => {
                      searchTerm ? send({ data: searchTerm, type: 'UPDATE_SEARCH' }) : send({ type: 'RESET_SEARCH' });
                    }}
                    onRemoveText={() => send({ type: 'RESET_SEARCH' })}
                    onShowCategories={() => send({ type: 'SHOW_CATEGORIES' })}
                    filter={categoryFilter}
                    withRadius={{ right: true, left: true }}
                  />
                  {matches({ Screen: { Home: { ContentLoaded: { Search: 'ShowingCategories' } } } }) && (
                    <CategoriesContainer>
                      <Categories>
                        <OutsideClickHandler
                          onOutsideClick={() => {
                            send({ type: 'HIDE_CATEGORIES' });
                          }}
                        >
                          <AlignRight onClick={() => send({ type: 'HIDE_CATEGORIES' })}>
                            <CategoryContainer>
                              <Icon name="chevron-up" size="medium" />
                              {categoryFilter && <FilterLabel>{categoryFilter}</FilterLabel>}
                            </CategoryContainer>
                          </AlignRight>
                          <ListContainer>
                            <List>
                              <Category
                                href="#"
                                onClick={() => send({ data: '', type: 'CHOOSE_CATEGORY' })}
                                selected={!categoryFilter}
                              >
                                {i18n[lang].all_categories}
                              </Category>
                              {filter &&
                                filter.map(category => (
                                  <Category
                                    href="#"
                                    key={category}
                                    onClick={() => send({ data: category, type: 'CHOOSE_CATEGORY' })}
                                    selected={category === categoryFilter}
                                  >
                                    {category}
                                  </Category>
                                ))}
                            </List>
                          </ListContainer>
                        </OutsideClickHandler>
                      </Categories>
                    </CategoriesContainer>
                  )}
                </SearchContainer>
              )}
            </HeaderBarItem>
            <HeaderBarItem>
              <MainNavigation />
            </HeaderBarItem>
          </HeaderBar>
        </HeaderContainer>
      </Sticky>
    </HeaderWrapper>
  );
};

const SearchContainer = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: ${({ theme }) => `calc(${theme.contentWidth.wide} - ${theme.spacing.sm} * 2)`};
`;

const AlignRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const CategoryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 9px;
  background: ${({ theme: { colors } }) => `${colors.grey[25]}`};
  cursor: pointer;
  background: ${({ theme: { colors } }) => `${colors.primary.white}`};
  height: 35px;
`;

const CategoriesContainer = styled.div`
  position: absolute;
  top: calc(${({ theme: { headerHeight } }) => headerHeight} - ${({ theme: { spacing } }) => spacing.xl});
  left: calc(${({ theme: { headerHeight } }) => headerHeight} + ${({ theme: { spacing } }) => `${spacing.sm} * 2`});
  right: calc(${({ theme: { headerHeight } }) => headerHeight} + ${({ theme: { spacing } }) => `${spacing.sm} * 2`});
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

const List = styled.div`
  box-shadow: 0 0.5rem 1rem ${({ theme: { colors } }) => colors.grey[75]};
`;

const Categories = styled.div`
  position: relative;
  margin: -33px auto 0 auto;
  max-width: ${({ theme }) => `calc(${theme.contentWidth.wide} - ${theme.spacing.sm} * 2)`};
`;

const Category = styled.a<{ selected: boolean }>`
  display: block;
  padding: ${({ theme: { spacing } }) => `${spacing.lg} ${spacing.xl}`};
  text-decoration: none;
  color: ${({ theme: { colors } }) => colors.grey[125]};
  font-size: 0.875rem;
  background: ${({ selected, theme: { colors } }) => (selected ? colors.grey[25] : colors.grey[10])};

  :hover {
    background: ${({ theme: { colors } }) => colors.grey[25]};
  }
`;
