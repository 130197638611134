import styled from '../../utils/styled-components';
import { fontDeclaration, TypoVariations, TypoVariationsMap, TypoWeights } from './typography';

type LabelProps = {
  variation?: TypoVariations;
};

export const Label = styled.span<LabelProps>`
  ${fontDeclaration};
  font-size: 1rem;
  line-height: 1rem;
  font-weight: ${TypoWeights.light};
  display: inline-flex;
  align-items: center;
  color: ${({ variation = TypoVariations.primary }) => TypoVariationsMap[variation]};
`;
