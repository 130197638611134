import firebase, { auth } from 'firebase';

//  Firebase config
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
};

//  FirebaseUI config for Google Login
export const uiConfig = {
  signInFlow: 'popup',
  signInSuccessUrl: '/',
  signInOptions: [new auth.GoogleAuthProvider().providerId],
};

//  Firebase microsoft provider
export const microsoftProvider = new auth.OAuthProvider('microsoft.com');

export default firebase.initializeApp(config);
