import React, { useContext } from 'react';

import { LoadingContent } from '../pages/loading';
import { Context } from '../states/context';
import { AppContext } from '../utils/app-context';

export const withLoading = <P extends object>(
  Component: React.FC<P>,
  key: keyof Context,
  state: string,
  substate: string = 'ContentLoaded',
) => () => {
  const { current } = useContext(AppContext);

  const { context, matches } = current;
  return (
    <>
      {matches({ Screen: { [state]: 'Fetching' } }) ? (
        <LoadingContent />
      ) : matches({ Screen: { [state]: substate } }) ? (
        <Component {...({ [key]: context[key] } as P)} />
      ) : (
        <div />
      )}
    </>
  );
};
