import { DoneInvokeEvent, EventObject, Machine, MachineConfig } from 'xstate';
import { assign, send } from 'xstate/lib/actions';

import { HrInfoError } from '../utils/error';
import {
  authenticate,
  getData,
  getSettings,
  OrderBy,
  promote,
  setPriority,
  signOut,
  Where,
} from '../utils/firebase-queries';
import { scrollToTop } from '../utils/helpers';
import { Context, defaultContext, Role, User } from './context';

interface EventObjectWithData extends EventObject {
  data?: any;
}

const persist = (key: string) =>
  assign<Context, EventObjectWithData>((_, { data }) => {
    return { [key]: data };
  });

const handleError = {
  actions: assign<Context, { data: HrInfoError | Error | undefined; type: string }>((_, { data }) => ({
    error: data,
  })),
  target: '#Root.Screen.Error',
};

const withFetching = (
  source: string,
  key: string,
  state: any,
  orderBy?: OrderBy,
  where?: { roleBased?: Role; where: Where },
): any => ({
  initial: 'Fetching',
  states: {
    Fetching: {
      invoke: {
        id: `fetch-${key}`,
        src: (ctx: Context) => {
          return getData(source, { where, orderBy, role: ctx.user && ctx.user.role });
        },
        onDone: {
          actions: persist(key),
          target: 'ContentLoaded',
        },
        onError: handleError,
      },
    },
    ContentLoaded: state,
  },
});

const FetchingI18nAndSettings = {
  invoke: {
    id: `fetch-i18n-and-settings`,
    src: getSettings,
    onDone: {
      actions: assign<Context, EventObjectWithData>((_ctx, { data: { i18n, settings } }) => {
        return { i18n, settings };
      }),
      target: '#Root.Screen.Authenticating',
    },
    onError: handleError,
  },
};

const LoggingOut = {
  invoke: {
    id: 'logOut',
    src: signOut,
    onDone: { target: '#Root.Screen.Authenticating', actions: assign<Context>(() => ({ user: undefined })) },
    onError: handleError,
  },
};

const Authenticating = {
  invoke: {
    id: 'authenticate',
    src: authenticate,
    onDone: [
      {
        target: 'Home',
        cond: (_: Context, { data }: DoneInvokeEvent<User>) => !!data,
        actions: assign<Context, EventObjectWithData>((_, { data }) => {
          return { user: data.user, settings: data.settings };
        }),
      },
      {
        target: 'ProvidingEmail',
        cond: (_: Context, { data }: DoneInvokeEvent<User>) => !data,
      },
    ],
    onError: 'ProvidingEmail',
  },
};

const Navigation = {
  HOME: {
    target: '#Root.Screen.Home',
    actions: [send<Context, EventObject>('CLOSE_NAVIGATION'), assign<Context>(() => ({ searchTerm: '' }))],
  },
  LOGOUT: { target: '#Root.Screen.LoggingOut', actions: send<Context, EventObjectWithData>('CLOSE_NAVIGATION') },
  USER_MANAGEMENT: {
    target: '#Root.Screen.UserManagement',
    actions: send<Context, EventObjectWithData>('CLOSE_NAVIGATION'),
  },
  ADMIN: { target: '#Root.Screen.Admin', actions: send<Context, EventObjectWithData>('CLOSE_NAVIGATION') },
  PRIVACY: { target: '#Root.Screen.Privacy', actions: send<Context, EventObjectWithData>('CLOSE_NAVIGATION') },
  CONTACT: {
    target: '#Root.Screen.Contact',
    actions: send<Context, EventObjectWithData>('CLOSE_NAVIGATION'),
  },
  LOGIN: { target: '#Root.Screen.ProvidingEmail', actions: send<Context, EventObjectWithData>('CLOSE_NAVIGATION') },
};

const ProvidingEmail = {
  on: {
    UPDATE_MAIL_ADDRESS: {
      actions: persist('email'),
    },
    PROVIDE_MAIL_ADRESS: {
      target: '#Root.Screen.EmailProvided',
      actions: (ctx: Context) => {
        assign<Context>(() => ({ privacy: true }));
        localStorage.setItem('privacy', 'true');
        if (ctx.email) {
          localStorage.setItem('email', ctx.email);
        } else {
          localStorage.removeItem('email');
        }
      },
    },
    AUTHENTICATE: {
      target: '#Root.Screen.Authenticating',
    },
    ...Navigation,
  },
};

const toggleHrItem = {
  TOGGLE_HR_ITEM: {
    actions: assign<Context, EventObjectWithData>(({ openedItems, openItems }, { data: { id } }) => {
      let updatedOpenItems;
      let updatedOpenedItems = openedItems;

      if (!id) {
        return { openedItems, openItems };
      }

      if (!openedItems.includes(id)) {
        setPriority(id);
        updatedOpenedItems = [...openedItems, id];
      }

      updatedOpenItems = openItems.includes(id) ? openItems.filter(itemId => itemId !== id) : [...openItems, id];

      return { openedItems: updatedOpenedItems, openItems: updatedOpenItems };
    }),
  },
};

const persistResetSearch = assign<Context>(() => ({ searchTerm: '' }));

const promoting = {
  Promoting: {
    invoke: {
      id: 'promote',
      src: promote,
      onDone: '#Root.Screen.Home',
    },
  },
};

export const Home = withFetching(
  '/hr/',
  'hr',
  {
    type: 'parallel',
    states: {
      Default: {
        on: {
          ...Navigation,
          ...toggleHrItem,
          PROMOTE: 'Default.Promoting',
        },
        initial: 'Default',
        states: {
          Default: {},
          ...promoting,
        },
      },
      Search: {
        initial: 'FulltextSearch',
        states: {
          FulltextSearch: {
            on: {
              UPDATE_SEARCH: {
                target: 'FulltextSearch',
                actions: [scrollToTop, persist('searchTerm')],
              },
              SHOW_CATEGORIES: {
                target: 'ShowingCategories',
              },
              CHOOSE_CATEGORY: {
                target: 'FulltextSearch',
                actions: [scrollToTop, assign<Context, EventObjectWithData>((_, { data }) => ({ categoryFilter: data }))],
              },
              RESET_SEARCH: {
                actions: [scrollToTop, persistResetSearch],
              },
            },
          },
          ShowingCategories: {
            on: {
              UPDATE_SEARCH: {
                target: 'FulltextSearch',
                actions: [scrollToTop, persist('searchTerm')],
              },
              CHOOSE_CATEGORY: {
                target: 'FulltextSearch',
                actions: [scrollToTop, assign<Context, EventObjectWithData>((_, { data }) => ({ categoryFilter: data }))],
              },
              HIDE_CATEGORIES: {
                target: 'FulltextSearch',
              },
              RESET_SEARCH: {
                target: 'FulltextSearch',
                actions: [scrollToTop, persistResetSearch],
              },
            },
          },
        },
      },
    },
  },
  { field: 'priority', order: 'desc' },
  { roleBased: Role.applicant, where: { field: 'visibility', compare: '==', value: 'public' } },
);

export const Admin = {
  initial: 'Default',
  states: {
    Default: {
      on: { ...Navigation },
    },
  },
};

export const UserManagement = withFetching(
  '/settings/authentication/users/',
  'users',
  {
    initial: 'Default',
    states: {
      Default: {
        on: { ...Navigation },
      },
    },
  },
  undefined,
  { roleBased: Role.recruiter, where: { field: 'role', compare: '==', value: Role.applicant } },
);

const EmailProvided = { on: { ...Navigation } };
const Privacy = { on: { ...Navigation } };
const Contact = { on: { ...Navigation } };

const Screen = {
  initial: 'FetchingI18nAndSettings',
  states: {
    FetchingI18nAndSettings,
    LoggingOut,
    Authenticating,
    ProvidingEmail,
    EmailProvided,
    Home,
    Admin,
    UserManagement,
    Privacy,
    Contact,
    Error: { on: { ...Navigation } },
  },
};

const UserLanguage = {
  initial: 'Default',
  states: {
    Default: {
      on: {
        SET_LANGUAGE: {
          actions: [
            (ctx: Context) => {
              if (ctx.lang) {
                localStorage.setItem('lang', ctx.lang);
              }
            },
            send<Context, EventObjectWithData>('CLOSE_NAVIGATION'), // (TransitionConfig<Context, any> & { event: any; })[]
            persist('lang'),
          ],
        },
      },
    },
  },
};

const shouldShowJoyride = (context: Context) => (callback: any) => {
  context.showJoyride ? callback('NEXT') : callback('CLOSE');
  return;
};

const JoyrideDefaultStates = {
  CLOSE: {
    actions: [
      () => {
        localStorage.setItem('showJoyride', 'false');
      },
    ],
    target: 'HideJoyride',
  },
};

const Joyride = {
  initial: 'ShouldShowJoyride',
  states: {
    ShouldShowJoyride: {
      invoke: {
        id: 'shouldShowJoyride',
        src: shouldShowJoyride,
      },
      on: {
        ...JoyrideDefaultStates,
        NEXT: {
          target: 'StartJoyride',
        },
      },
    },
    StartJoyride: {
      on: {
        ...JoyrideDefaultStates,
        NEXT: {
          target: 'SearchBar',
        },
      },
    },
    SearchBar: {
      after: {
        800: {
          actions: send<Context, EventObjectWithData>({ type: 'UPDATE_SEARCH', data: 'A' }),
        },
        1200: {
          actions: send<Context, EventObjectWithData>({ type: 'UPDATE_SEARCH', data: 'AH' }),
        },
        1500: {
          actions: send<Context, EventObjectWithData>({ type: 'UPDATE_SEARCH', data: 'AHV' }),
        },
      },
      on: {
        ...JoyrideDefaultStates,
        PREVIOUS: {
          target: 'StartJoyride',
        },
        NEXT: {
          target: 'Categories',
          actions: send<Context, EventObjectWithData>({ type: 'UPDATE_SEARCH', data: '' }),
        },
      },
    },
    Categories: {
      after: {
        1500: {
          actions: send<Context, EventObjectWithData>('SHOW_CATEGORIES'),
        },
      },
      on: {
        ...JoyrideDefaultStates,
        PREVIOUS: {
          target: 'SearchBar',
        },
        NEXT: {
          target: 'Menu',
        },
      },
    },
    Menu: {
      after: {
        1500: {
          actions: send<Context, EventObjectWithData>('OPEN_NAVIGATION'),
        },
      },
      on: {
        ...JoyrideDefaultStates,
        PREVIOUS: {
          target: 'Categories',
          actions: [
            send<Context, EventObjectWithData>('CLOSE_NAVIGATION'),
            send<Context, EventObjectWithData>('SHOW_CATEGORIES'),
          ],
        },
        NEXT: {
          target: 'ContentItem',
          actions: send<Context, EventObjectWithData>('CLOSE_NAVIGATION'),
        },
      },
    },
    ContentItem: {
      on: {
        ...JoyrideDefaultStates,
        PREVIOUS: {
          target: 'Menu',
        },
        NEXT: {
          target: 'More',
        },
      },
    },
    More: {
      after: {
        1000: {
          actions: send<Context, EventObjectWithData>((ctx: Context) => ({
            type: 'TOGGLE_HR_ITEM',
            data: { id: ctx.hr![0].id },
          })),
        },
      },
      on: {
        ...JoyrideDefaultStates,
        PREVIOUS: {
          target: 'ContentItem',
        },
        NEXT: {
          actions: send<Context, EventObjectWithData>((ctx: Context) => {
            if (ctx.openItems.includes(ctx.hr![0].id)) {
              return { type: 'TOGGLE_HR_ITEM', data: { id: ctx.hr![0].id } };
            }
            return { type: 'TOGGLE_HR_ITEM' };
          }),
          target: 'ScrollDown',
        },
      },
    },
    ScrollDown: {
      after: {
        1000: {
          actions: () => window.scroll({ top: 1100, behavior: 'smooth' }),
        },
      },
      on: {
        ...JoyrideDefaultStates,
        PREVIOUS: {
          target: 'More',
        },
        NEXT: {
          target: 'BackToTop',
        },
      },
    },
    BackToTop: {
      after: {
        1000: {
          actions: scrollToTop,
        },
      },
      on: {
        ...JoyrideDefaultStates,
        PREVIOUS: {
          target: 'ScrollDown',
        },
        NEXT: {
          target: 'OurLogo',
        },
      },
    },
    OurLogo: {
      after: {
        1000: {
          actions: send<Context, EventObjectWithData>({ type: 'UPDATE_SEARCH', data: '' }),
        },
      },
      on: {
        ...JoyrideDefaultStates,
        PREVIOUS: {
          target: 'ScrollDown',
        },
        NEXT: {
          target: 'GoodToGo',
        },
      },
    },
    GoodToGo: {
      on: {
        ...JoyrideDefaultStates,
        PREVIOUS: {
          target: 'OurLogo',
        },
      },
    },
    HideJoyride: {
      on: {
        START: {
          actions: [send<Context, EventObjectWithData>('CLOSE_NAVIGATION'), send<Context, EventObjectWithData>('HOME')],
          target: 'StartJoyride',
        },
      },
    },
  },
};

const NavigationControl = {
  initial: 'NavigationIsClosed',
  states: {
    NavigationIsClosed: {
      on: {
        OPEN_NAVIGATION: {
          target: 'NavigationisOpen',
          actions: assign<Context>(() => ({ navigationOpen: true })),
        },
      },
    },
    NavigationisOpen: {
      on: {
        CLOSE_NAVIGATION: {
          target: 'NavigationStartsClosing',
        },
      },
    },
    NavigationStartsClosing: {
      after: {
        20: {
          target: 'NavigationIsClosing',
          actions: assign<Context>(() => ({ navigationOpen: false, navigationIsClosing: true })),
        },
      },
    },
    NavigationIsClosing: {
      after: {
        600: { target: 'NavigationIsClosed', actions: assign<Context>(() => ({ navigationIsClosing: false })) },
      },
    },
  },
};

export const States: MachineConfig<Context, any, any> = {
  id: 'Root',
  type: 'parallel',
  context: defaultContext,
  states: {
    Screen,
    UserLanguage,
    NavigationControl,
    Joyride,
  },
};

export const stateMachine = Machine<Context>(States);
