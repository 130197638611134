import { Language } from './config';
import { ErrorTexts, I18N } from './i18n';

const FALLBACK_LANGUAGE = 'en';

export const getErrorMessage = (
  error: Error | HrInfoError | { key: keyof ErrorTexts } | undefined,
  lang: Language,
  i18n: I18N,
) => {
  if (!error) {
    return undefined;
  }
  const messageFromKey = 'key' in error && `${(i18n[lang] && i18n[lang][error.key]) || i18n[FALLBACK_LANGUAGE][error.key]}`;
  const message = 'message' in error && error.message;

  return (messageFromKey && message && `${messageFromKey} : ${message}`) || messageFromKey || message || undefined;
};

export class HrInfoError extends Error {
  public key: keyof ErrorTexts;
  public message: string;
  constructor(key: keyof ErrorTexts, message: string = '') {
    super();
    this.key = key;
    this.message = message;
  }
}
