import React, { useContext } from 'react';

import { Link } from '../elements/link';
import { Icon, TextIcon } from '../identity/icon';
import { Prose } from '../identity/typography/prose';
import { SecondaryTitle } from '../identity/typography/title';
import { TypoVariations } from '../identity/typography/typography';
import { List, ListRow, ListRowItem } from '../layout/list';
import { AppContext } from '../utils/app-context';
import styled from '../utils/styled-components';

const NotFoundWrapper = styled.div`
  flex: 1;
  padding-top: ${({ theme: { spacing } }) => spacing.xxl};
  padding-bottom: ${({ theme: { spacing } }) => spacing.xxl};
`;

export const NotFound = () => {
  const { current } = useContext(AppContext);
  const {
    context: { lang, i18n },
  } = current;

  if (!i18n || !lang || !i18n[lang]) {
    return <div />;
  }

  const {
    no_results,
    no_results_title: title,
    no_results_email_adress: emailAdress,
    no_results_email_text: emailText,
    no_results_phone_number: phoneNumber,
    no_results_skype_link: skypeLink,
    no_results_skype_text: skypeText,
    no_results_slack_link: slackLink,
    no_results_slack_text: slackText,
    no_results_whatsapp_link: whatsappLink,
    no_results_whatsapp_text: whatsappText,
  } = i18n[lang];

  return (
    <NotFoundWrapper>
      <List>
        <ListRow>
          <ListRowItem fixWidth>
            <CenterIcon>
              <Icon name="question" size="medium" />
            </CenterIcon>
          </ListRowItem>
          <ListRowItem>
            <SecondaryTitle>{title}</SecondaryTitle>
            <Prose variation={TypoVariations.secondary}>{no_results}</Prose>
            <>
              {phoneNumber && (
                <Contact>
                  <Link href={`tel:${phoneNumber}`}>
                    <TextIcon name="phone" />
                    {phoneNumber}
                  </Link>
                </Contact>
              )}
              {emailAdress && emailText && (
                <Contact>
                  <Link href={`mailto:${emailAdress}`}>
                    <TextIcon name="envelope" />
                    {emailText}
                  </Link>
                </Contact>
              )}
              {skypeLink && skypeText && (
                <Contact>
                  <Link href={skypeLink}>
                    <TextIcon name="skype" />
                    {skypeText}
                  </Link>
                </Contact>
              )}
              {slackLink && slackText && (
                <Contact>
                  <Link href={slackLink}>
                    <TextIcon name="slack-hash" />
                    {slackText}
                  </Link>
                </Contact>
              )}
              {whatsappLink && whatsappText && (
                <Contact>
                  <Link href={whatsappLink}>
                    <TextIcon name="whatsapp" />
                    {whatsappText}
                  </Link>
                </Contact>
              )}
            </>
          </ListRowItem>
        </ListRow>
      </List>
    </NotFoundWrapper>
  );
};

const CenterIcon = styled.div`
  display: flex;
  padding-top: 3px;
  justify-content: center;
`;

const Contact = styled.div``;
