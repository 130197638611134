import React, { Component, FC, ReactNode, useContext } from 'react';
import { State } from 'xstate';

import { Link } from '../elements/link';
import { Icon, IconName, TextIcon } from '../identity/icon';
import { Prose, ProseWithInnerHTML } from '../identity/typography/prose';
import { SecondaryTitle } from '../identity/typography/title';
import { TypoVariations, TypoWeights } from '../identity/typography/typography';
import { ListRow, ListRowItem } from '../layout/list';
import { Context, Role, User } from '../states/context';
import { AppContext } from '../utils/app-context';
import { LangArrayString, LangString, Language } from '../utils/config';
import fb from '../utils/firebase';
import { I18N } from '../utils/i18n';
import { sanitize } from '../utils/sanitize';
import styled from '../utils/styled-components';

const storage = fb.storage();

export interface HRInfoItemProps {
  id: string;
  title: LangString;
  short: LangString;
  icon: IconName;
  url: LangArrayString;
  event?: Event;
  categories: LangArrayString;
  search_terms: LangArrayString;
}

interface HRInfoItemWithContextProps extends HRInfoItemProps {
  i18n: I18N;
  lang: Language;
  transition: (
    event: any,
    payload?: (Record<string, any> & { type?: undefined }) | undefined,
  ) => State<Context, any, any, any>;
  user: User;
  showDetails: boolean;
}

export const HRInfoItem = (props: HRInfoItemProps) => {
  const { current, transition } = useContext(AppContext);

  const {
    context: { i18n, lang, user, openItems },
  } = current;

  if (!user) {
    return null;
  }

  return (
    <HRInfoItemWithContext
      {...props}
      i18n={i18n}
      lang={lang}
      transition={transition}
      user={user}
      showDetails={openItems.includes(props.id)}
    />
  );
};

export class HRInfoItemWithContext extends Component<
  HRInfoItemWithContextProps,
  {
    initialHeight: string;
    detailsHeight: string;
    hasInitiated: boolean;
    windowWidth: number;
  }
> {
  private initialElement: any = React.createRef<HTMLDivElement>();
  private detailElement: any = React.createRef<HTMLDivElement>();

  constructor(props: HRInfoItemWithContextProps) {
    super(props);

    this.state = {
      initialHeight: '0px',
      detailsHeight: '0px',
      windowWidth: 0,
      hasInitiated: false,
    };
  }

  public handleClick(id: string): void {
    this.props.transition('TOGGLE_HR_ITEM', { data: { id } });
  }

  public updateWindowSize = (): void => {
    if (Math.abs(this.state.windowWidth - window.innerWidth) > 100) {
      this.props.transition('HOME');
    }
  };

  public handlePromoteToTop = (id: string): void => {
    this.props.transition('PROMOTE', { data: { id } });
  };

  public updateDimensions = (): void => {
    const initialHeight =
      this.initialElement.current.clientHeight < 4 * 16 ? `${this.initialElement.current.clientHeight}px` : '4rem';
    const detailsHeight = `${this.detailElement.current.clientHeight}px`;
    this.setState({
      initialHeight,
      detailsHeight,
      hasInitiated: true,
      windowWidth: window.innerWidth,
    });
  };

  public componentDidMount(): void {
    this.updateDimensions();
    window.addEventListener('resize', this.updateWindowSize);
  }

  public componentDidUpdate(): void {
    if (!this.state.hasInitiated) {
      this.updateDimensions();
    }
  }

  public componentWillReceiveProps(newProps: HRInfoItemWithContextProps): void {
    if (newProps.lang !== this.props.lang) {
      this.setState({ initialHeight: '0px', detailsHeight: '0px', hasInitiated: false });
    }
  }

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.updateWindowSize);
  }

  public render(): ReactNode {
    const { id, icon, title, short, url, lang, i18n, user, categories, transition, showDetails } = this.props;
    const { hasInitiated } = this.state;

    return (
      <ListRowWrapper>
        <ListRow key={id} onClick={() => this.handleClick(id)}>
          <ListRowItem fixWidth>
            <CenterIcon>{icon && <Icon name={icon} size="medium" />}</CenterIcon>
          </ListRowItem>
          <ListRowItem>
            <SecondaryTitle dangerouslySetInnerHTML={{ __html: sanitize(title[lang]) }} />
            <Animate height={showDetails ? this.state.detailsHeight : this.state.initialHeight}>
              <div ref={this.initialElement}>
                {!showDetails && <ProseWithInnerHTML variation={TypoVariations.secondary} content={short[lang]} />}
              </div>
              <div ref={this.detailElement}>
                {(showDetails || !hasInitiated) && (
                  <ProseWithInnerHTML variation={TypoVariations.secondary} content={short[lang]} />
                )}
                <div>
                  {(showDetails || !hasInitiated) && url[lang] && (
                    <>
                      <DownloadLink names={url[lang]} getURL={showDetails} />
                      <CategoryContainer>
                        {categories[lang].map(cat => (
                          <Category key={cat} onClick={() => transition('CHOOSE_CATEGORY', { data: cat })}>
                            {cat}
                          </Category>
                        ))}
                      </CategoryContainer>
                      {user.role === Role.admin && (
                        <PromoteToTop onClick={() => this.handlePromoteToTop(id)} text={i18n[lang].promote} />
                      )}
                    </>
                  )}
                </div>
              </div>
            </Animate>
            <ShowMore>
              <PositionRight>
                <Prose>{showDetails ? i18n[lang].less : i18n[lang].more}</Prose>
              </PositionRight>
            </ShowMore>
          </ListRowItem>
        </ListRow>
      </ListRowWrapper>
    );
  }
}

const CategoryContainer = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing.lg};
`;

const Category = styled(Link)`
  background: ${({ theme: { colors } }) => colors.grey[25]};
  font-size: 0.8rem;
  border-radius: ${({ theme: { spacing } }) => spacing.md};
  margin: ${({ theme: { spacing } }) => spacing.sm};
  padding: ${({ theme: { spacing } }) => spacing.md};
  display: inline;
`;

const PromoteToTop: FC<{ onClick: () => void; text: string | undefined }> = ({ onClick, text }) => (
  <ToTopContainer>
    <Link href="#" onClick={onClick}>
      <TextIcon name="level-up-alt" />
      {text}
    </Link>
  </ToTopContainer>
);

const ToTopContainer = styled.div`
  font-size: 0.8rem;
`;

const ShowMore = styled.div`
  opacity: 0.8;
  background: ${({ theme: { colors } }) => colors.primary.white};
  margin-top: -1.125rem;
  padding-top: 0.5rem;
`;

const PositionRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: ${({ theme: { spacing } }) => spacing.xxl};
`;

type DownloadLinkProps = {
  names: string[];
  getURL: boolean;
};

class DownloadLink extends Component<DownloadLinkProps, { documents?: { name: string; url?: string }[] }> {
  constructor(props: DownloadLinkProps) {
    super(props);
    this.state = {};
  }
  public async componentDidMount(): Promise<void> {
    if (Array.isArray(this.props.names) && this.props.getURL) {
      const documentNames = this.props.names;
      const documents = [];
      for (const documentName of documentNames) {
        try {
          const url = storage.refFromURL(`gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${documentName}`);
          const downloadUrl = await url.getDownloadURL();
          documents.push({ name: documentName, url: downloadUrl });
        } catch (e) {}
      }

      this.setState({
        documents,
      });
    }
  }
  public render(): JSX.Element | null {
    const documents =
      this.state.documents ||
      (Array.isArray(this.props.names) && this.props.names.map(d => ({ name: d, url: undefined }))) ||
      [];

    return (
      <DownloadLinkContainer>
        {documents.map(d => (
          <DownloadLinkElement href={d.url || undefined} target="_blank" key={d.name} aria-label={d.name}>
            <TextIcon name={d.name.includes('.pdf') ? 'file-pdf' : 'file-alt'} />
            {d.name.replace('.pdf', '')}
          </DownloadLinkElement>
        ))}
      </DownloadLinkContainer>
    );
  }
}

const DownloadLinkElement = styled(Link)`
  font-weight: ${TypoWeights.regular};
`;

const CenterIcon = styled.div`
  display: flex;
  padding-top: 3px;
  justify-content: center;
`;

const DownloadLinkContainer = styled.div`
  display: inline-block;
  padding: 0.5rem 0;
`;

const ListRowWrapper = styled.div`
  cursor: pointer;
`;

const Animate = styled.div<{ height: string }>`
  transition: height 400ms ease-in-out;
  ${({ height }) => `height:${height}`};
  overflow: hidden;
  padding-bottom: 1rem;
`;
