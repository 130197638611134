import { firestore, User as FirebaseUser } from 'firebase';

import { Color, defaultColors } from '../identity/colors';
import { Context, Role, RoleBasedTimeout, SearchSettings, Settings, User, UserSettings } from '../states/context';
import { Language } from './config';
import { HrInfoError } from './error';
import fb from './firebase';
import { I18N, Texts } from './i18n';
import { promiseTimeout } from './promise-timeout';

export const getSettings = async () => {
  const i18nSnapshots = await promiseTimeout<firebase.firestore.QuerySnapshot>(
    fb
      .firestore()
      .collection('i18n')
      .get(),
  );

  const i18n: I18N = {};

  i18nSnapshots.forEach(snapshot => (i18n[snapshot.id] = snapshot.data() as Texts));

  const search = (
    await promiseTimeout<firebase.firestore.DocumentSnapshot>(
      fb
        .firestore()
        .collection('settings')
        .doc('search')
        .get(),
    )
  ).data() as SearchSettings;

  const clrs = await promiseTimeout<firebase.firestore.QuerySnapshot>(
    fb
      .firestore()
      .collection('settings/theme/colors')
      .get(),
  );

  const colors = defaultColors;

  clrs.forEach(color => (colors[color.id] = color.data()));

  const settings: Settings = { search, theme: { colors: colors as Color } };

  return { i18n, settings };
};

export const authenticate = async (ctx: Context): Promise<{ user: User | undefined; settings: Settings } | undefined> => {
  let user = await new Promise<FirebaseUser | null>(resolve => {
    const unsub = fb.auth().onAuthStateChanged(user => {
      unsub();
      resolve(user);
    });
  });

  if (!user) {
    if (!fb.auth().isSignInWithEmailLink(window.location.href)) {
      return;
    }
    if (!ctx.email) {
      return;
    }
    await fb.auth().signInWithEmailLink(ctx.email, window.location.href);
    user = fb.auth().currentUser;

    if (!user) {
      throw new HrInfoError('error_user_not_set');
    }
  }

  let role = undefined;

  try {
    role = ((await getData<UserSettings>(`settings/authentication/users/${user.email}`)) as UserSettings).role;
  } catch (e) {
    // We do not need to throw if the user role can not be found, since there are users which have access based on their mail adress domain.
  }

  if (!role) {
    const providerIds = user.providerData && user.providerData.map(user => user && user.providerId);
    const domain = user.email && user.email.split('@')[1];

    if (providerIds.includes('microsoft.com') || providerIds.includes('google.com')) {
      role = Role.user;
    } else if (domain) {
      try {
        await getData(`settings/authentication/domains/allowAllDomains`);
        role = Role.user;
      } catch (e) {
        // We do not need to throw if the document can not be found, this will be thrown below
      }

      try {
        await getData(`settings/authentication/domains/${domain}`);
        role = Role.user;
      } catch (e) {
        // We do not need to throw if the document can not be found, this will be thrown below
      }
    }
  }

  if (!role) {
    throw new HrInfoError('error_authentication');
  }

  let roleBasedAccessTimeout;

  if (role === Role.admin || role === Role.recruiter) {
    roleBasedAccessTimeout = (
      await promiseTimeout<firebase.firestore.DocumentSnapshot>(
        fb
          .firestore()
          .collection('settings')
          .doc('authentication/settings/timeout')
          .get(),
      )
    ).data() as RoleBasedTimeout;
  }

  return {
    user: {
      role,
      email: user.email || undefined,
      uid: user.uid,
    },
    settings: {
      ...ctx.settings,
      timeout: roleBasedAccessTimeout,
    },
  };
};

export const signOut = async (): Promise<void> => {
  await fb.auth().signOut();
};

export const setPriority = (id: string) => {
  fb.firestore().runTransaction(async transaction => {
    const itemRef = fb
      .firestore()
      .collection('hr')
      .doc(id);

    const item = await transaction.get(itemRef);

    if (item && item.exists) {
      const newPriority = item.data()!.priority + 1;
      transaction.update(itemRef, { priority: newPriority });
    }
  });
};

export const promote = async (_ctx: Context, { data: { id } }: { data: { id: string } }) => {
  if (id) {
    await fb.firestore().runTransaction(async transaction => {
      const snapshots = await fb
        .firestore()
        .collection('hr')
        .orderBy('priority', 'desc')
        .limit(1)
        .get();

      let highestPriority;

      snapshots.forEach(d => {
        highestPriority = d.data().priority;
      });

      const itemRef = fb
        .firestore()
        .collection('hr')
        .doc(id);

      if (highestPriority) {
        await transaction.update(itemRef, { priority: highestPriority + 1 });
      }
    });
  }
  return;
};

const isCollection = (path: string) => (path.match(/.\/./g) || []).length % 2 === 0;

export type OrderBy = {
  field: string;
  order: 'desc' | 'asc';
};

export type Where = {
  field: string;
  compare: firestore.WhereFilterOp;
  value: string;
};

export type GetDataSettings = {
  role?: Role;
  orderBy?: OrderBy;
  where?: { roleBased?: Role; where: Where };
};

export async function getData<P>(path: string, settings: GetDataSettings = {}): Promise<P[] | P> {
  const { role, orderBy, where } = settings;
  if (isCollection(path)) {
    let query = fb.firestore().collection(path) as firestore.Query;

    if (orderBy) {
      query = query.orderBy(orderBy.field, orderBy.order);
    }

    if (where) {
      if ((where.roleBased && where.roleBased === role) || !where.roleBased) {
        const {
          where: { field, compare, value },
        } = where;
        query = query.where(field, compare, value);
      }
    }

    const snapshots = await promiseTimeout(query.get());

    if (!snapshots) {
      throw new HrInfoError('error_no_data');
    }

    const data: P[] = [];
    snapshots.forEach(snapshot => data.push({ ...(transform(snapshot.data()) as P), id: snapshot.id }));

    if (!data || data.length === 0) {
      throw new HrInfoError('error_no_data');
    }

    return data;
  } else {
    const snapshot = await promiseTimeout(
      fb
        .firestore()
        .doc(path)
        .get(),
    );

    if (!snapshot) {
      throw new HrInfoError('error_no_data');
    }

    const data = transform(snapshot.data()) as P;
    if (!data) {
      throw new HrInfoError('error_no_data');
    }

    return data;
  }
}

function transform(data: any): any {
  const langs = Object.keys(Language).map(l => ({ string: l, regex: new RegExp(`.+?(?=_${l}\\b)`) }));
  const keys = Object.keys(data);

  return keys.reduce((acc, cur) => {
    for (const lang of langs) {
      const field = cur.match(lang.regex);
      if (field && field[0]) {
        return { ...acc, [field[0]]: { ...acc[field[0]], [lang.string]: data[cur] } };
      }
    }
    return { ...acc, [cur]: data[cur] };
  }, {});
}
