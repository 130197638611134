import React, { FC, useEffect, useState } from 'react';

import { Icon } from '../identity/icon';
import { scrollToTop } from '../utils/helpers';
import styled from '../utils/styled-components';

const THRESHOLD = 1000;

export const ScrollToTop: FC = () => {
  const onClick = () => {
    scrollToTop();
  };
  const [visible, setVisibility] = useState(false);

  useEffect(() => {
    const handler = () => {
      const shouldBeVisible = document.body.scrollTop > THRESHOLD || document.documentElement.scrollTop > THRESHOLD;

      if (shouldBeVisible !== visible) {
        setVisibility(shouldBeVisible);
      }
    };

    window.addEventListener('scroll', handler);

    // clean up
    return () => window.removeEventListener('scroll', handler);
  }, [visible, setVisibility]);

  return (
    <ScrollToTopContainer onClick={onClick} visible={visible}>
      <Icon name="chevron-up" size="medium" variation="dark" />
    </ScrollToTopContainer>
  );
};

const ScrollToTopContainer = styled.button<{ visible: boolean }>`
  transition: all 300ms ease-in-out;
  opacity: ${({ visible }) => (!visible ? '0.001' : '1;')};
  bottom: ${({ visible }) => (visible ? '90px' : '-100px')};
  position: fixed;
  right: 30px;
  z-index: 99;
  outline: none;
  cursor: pointer;
  border-radius: 30px;
  width: 45px;
  height: 45px;
  background: ${({ theme: { colors } }) => colors.brandcolor[100]};

  &:hover,
  &:active {
    background: ${({ theme: { colors } }) => colors.brandcolor[200]};
    outline: 0;
    cursor: pointer;
    opacity: 0.8;
  }
`;
