import React, { FC } from 'react';

import styled from '../utils/styled-components';

const UnorderedList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

const Block = styled.div`
  flex: 1;
  display: block;
  box-sizing: border-box;
  padding: ${({ theme: { spacing } }) => `0 ${spacing.sm}`};
`;

export const List: FC = ({ children }) => (
  <Block>
    <UnorderedList>{children}</UnorderedList>
  </Block>
);

export const ListRow = styled.li`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  padding: ${({ theme: { spacing } }) => `${spacing.lg} ${spacing.sm}`};
  background: ${({ theme: { colors } }) => colors.primary.white};
  margin-bottom: 1px;

  &:first-of-type {
    margin-top: ${({ theme: { spacing } }) => spacing.sm};
  }
  &:last-of-type {
    border-bottom: none;
  }
`;

type ListRowItemProps = {
  fixWidth?: boolean;
  width?: string;
};

export const ListRowItem = styled.div<ListRowItemProps>`
  ${({ fixWidth, width }) => (fixWidth ? `min-width: ${width ? width : '50px'}` : 'flex: 1')};
  text-overflow: ellipsis;
  box-sizing: border-box;

  &:first-of-type {
    padding-right: ${({ theme: { spacing } }) => spacing.sm};
  }

  &:last-of-type {
    padding-left: ${({ theme: { spacing } }) => spacing.sm};
  }

  &:only-child {
    padding-right: 0;
    padding-left: 0;
  }
`;

type FlexListRowItemProps = {
  wide?: boolean;
};

export const FlexListRowItem = styled(ListRowItem)<FlexListRowItemProps>`
  ${({ wide }) => `flex: ${wide ? '3' : '1'}`};
`;
