import React, { FC, ReactChild } from 'react';
import { EventObject } from 'xstate';

import { Link } from '../elements/link';
import { Icon } from '../identity/icon';
import { PageTitle } from '../identity/typography/title';
import { AppContainer } from '../layout/app-layout';
import { Modal, ModalContainer, ModalContentItem } from '../layout/modal';
import { User } from '../states/context';
import styled from '../utils/styled-components';

export type SimplePageProps = {
  title?: string;
  children: ReactChild;
  closeNavigation?: string;
  user?: User;
  transition: (event: EventObject) => void;
};

export const SimplePage: FC<SimplePageProps> = ({ title, children, closeNavigation, user, transition }) => (
  <AppContainer>
    <ModalContainer>
      <Modal>
        <ModalContentItem>
          <ModalNavigation>
            <Link href="#" onClick={() => transition({ type: user ? 'HOME' : 'LOGIN' })}>
              <Close>
                {closeNavigation} <Icon name="times" size="small" texticon />
              </Close>
            </Link>
          </ModalNavigation>
        </ModalContentItem>
        <ModalContentItem>
          <PageTitle>{title}</PageTitle>
        </ModalContentItem>
        <ModalContentItem>{children}</ModalContentItem>
      </Modal>
    </ModalContainer>
  </AppContainer>
);

const ModalNavigation = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

const Close = styled.div`
  display: flex;
  align-items: center;
`;
