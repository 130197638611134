import { css, SimpleInterpolation } from 'styled-components';

import { theme } from '../../utils/theme';

/* For Fontsizes see: https://type-scale.com/?size=15&scale=1.250&text=A%20Visual%20Type%20Scale&font=Poppins&fontweight=400&bodyfont=Poppins&bodyfontweight=400&backgroundcolor=white&fontcolor=%23333&preview=false */

export const fontDeclaration: SimpleInterpolation = css`
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', 'Fira Sans', Ubuntu,
    Oxygen, 'Oxygen Sans', Cantarell, 'Droid Sans', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Lucida Grande', Helvetica, Arial, sans-serif;
`;

export enum TypoWeights {
  light = 200,
  regular = 300,
  medium = 500,
  bold = 500,
}

export enum TypoVariations {
  primary,
  secondary,
  tertiary,
  accent,
  inverse,
  alarm,
}

export const TypoVariationsMap = {
  [TypoVariations.primary]: theme.colors.primary.black,
  [TypoVariations.secondary]: theme.colors.grey[100],
  [TypoVariations.tertiary]: theme.colors.grey[75],
  [TypoVariations.accent]: theme.colors.brandcolor[125],
  [TypoVariations.inverse]: theme.colors.primary.white,
  [TypoVariations.alarm]: theme.colors.alarm[100],
};
