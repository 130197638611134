import React, { FC, useContext } from 'react';

import { Prose } from '../identity/typography/prose';
import { PageTitle } from '../identity/typography/title';
import { TypoVariations } from '../identity/typography/typography';
import { EmptyAppContainer } from '../layout/app-layout';
import { Modal, ModalContainer, ModalContentItem } from '../layout/modal';
import { AppContext } from '../utils/app-context';
import { getErrorMessage } from '../utils/error';
import styled from '../utils/styled-components';

export const Error: FC = () => {
  const { current } = useContext(AppContext);
  const {
    context: { error, i18n, lang },
  } = current;

  return (
    <EmptyAppContainer>
      <ModalContainer>
        <Modal>
          <ModalContentItem>
            <PageTitle>{getErrorMessage({ key: 'error_title' }, lang, i18n)}</PageTitle>
          </ModalContentItem>
          <ModalContentItem>
            <Prose variation={TypoVariations.secondary}> {getErrorMessage(error, lang, i18n)}</Prose>
            <Reload href="/">{getErrorMessage({ key: 'error_reload' }, lang, i18n)}</Reload>
          </ModalContentItem>
        </Modal>
      </ModalContainer>
    </EmptyAppContainer>
  );
};

const Reload = styled.a`
  display: block;
  cursor: pointer;
`;
