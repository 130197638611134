import React, { FC, useContext } from 'react';

import { SimplePage } from '../compositions/simple-page';
import { Link } from '../elements/link';
import { TextIcon } from '../identity/icon';
import { Prose } from '../identity/typography/prose';
import { TypoVariations } from '../identity/typography/typography';
import { AppContext } from '../utils/app-context';
import { sanitize } from '../utils/sanitize';

export const Contact: FC = () => {
  const { current, transition } = useContext(AppContext);
  const {
    context: { lang, i18n, user },
  } = current;
  const {
    contact_title,
    close_navigation,
    contact_text,
    no_results_email_adress: emailAdress,
    no_results_email_text: emailText,
    no_results_phone_number: phoneNumber,
    no_results_skype_link: skypeLink,
    no_results_skype_text: skypeText,
    no_results_slack_link: slackLink,
    no_results_slack_text: slackText,
    no_results_whatsapp_link: whatsappLink,
    no_results_whatsapp_text: whatsappText,
    contact_text_technical,
    tech_support_email_adress: techEmailAdress,
    tech_support_email_text: techEmailText,
    tech_support_phone_number: techPhoneNumber,
    tech_support_skype_link: techSkypeLink,
    tech_support_skype_text: techSkypeText,
    tech_support_slack_link: techSlackLink,
    tech_support_slack_text: techSlackText,
    tech_support_whatsapp_link: techWhatsappLink,
    tech_support_whatsapp_text: techWhatsappText,
  } = i18n[lang];

  return (
    <SimplePage user={user} transition={transition} title={contact_title} closeNavigation={close_navigation}>
      <div>
        <Prose variation={TypoVariations.secondary}>
          <span dangerouslySetInnerHTML={{ __html: sanitize(contact_text) }} />
        </Prose>
        <Prose>
          {phoneNumber && (
            <div>
              <Link href={`tel:${phoneNumber}`}>
                <TextIcon name="phone" />
                {phoneNumber}
              </Link>
            </div>
          )}
          {emailAdress && emailText && (
            <div>
              <Link href={`mailto:${emailAdress}`}>
                <TextIcon name="envelope" />
                {emailText}
              </Link>
            </div>
          )}
          {skypeLink && skypeText && (
            <div>
              <Link href={skypeLink}>
                <TextIcon name="skype" />
                {skypeText}
              </Link>
            </div>
          )}
          {slackLink && slackText && (
            <div>
              <Link href={slackLink}>
                <TextIcon name="slack-hash" />
                {slackText}
              </Link>
            </div>
          )}
          {whatsappLink && whatsappText && (
            <div>
              <Link href={whatsappLink}>
                <TextIcon name="whatsapp" />
                {whatsappText}
              </Link>
            </div>
          )}
        </Prose>
        <Prose variation={TypoVariations.secondary}>
          <span dangerouslySetInnerHTML={{ __html: sanitize(contact_text_technical) }} />
        </Prose>
        <Prose>
          {techPhoneNumber && (
            <div>
              <Link href={`tel:${techPhoneNumber}`}>
                <TextIcon name="phone" />
                {techPhoneNumber}
              </Link>
            </div>
          )}
          {techEmailAdress && techEmailText && (
            <div>
              <Link href={`mailto:${techEmailAdress}`}>
                <TextIcon name="envelope" />
                {techEmailText}
              </Link>
            </div>
          )}
          {techSkypeLink && techSkypeText && (
            <div>
              <Link href={techSkypeLink}>
                <TextIcon name="skype" />
                {techSkypeText}
              </Link>
            </div>
          )}
          {techSlackLink && techSlackText && (
            <div>
              <Link href={techSlackLink}>
                <TextIcon name="slack-hash" />
                {techSlackText}
              </Link>
            </div>
          )}
          {techWhatsappLink && techWhatsappText && (
            <div>
              <Link href={techWhatsappLink}>
                <TextIcon name="whatsapp" />
                {techWhatsappText}
              </Link>
            </div>
          )}
        </Prose>
      </div>
    </SimplePage>
  );
};
