import React, { useContext, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { Alert, Form, Status } from '../components/form';
import { Input } from '../elements/input';
import { Label } from '../identity/typography/label';
import { Prose } from '../identity/typography/prose';
import { SecondaryTitle } from '../identity/typography/title';
import { TypoVariations } from '../identity/typography/typography';
import { AppContainer } from '../layout/app-layout';
import { Modal, ModalContentItem } from '../layout/modal';
import { AppContext } from '../utils/app-context';
import { isValidEmail } from '../utils/config';
import { getErrorMessage, HrInfoError } from '../utils/error';
import fb, { microsoftProvider, uiConfig } from '../utils/firebase';
import styled from '../utils/styled-components';

export const Login = () => {
  const [microsoftLoginStatus, setMicrosoftLoginStatus] = useState<Status | undefined>(undefined);
  const [emailLoginStatus, setEmailLoginStatus] = useState<Status | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isCheckedSocialLogin, setIsCheckedSocialLogin] = useState<boolean>(false);
  const [isCheckedEmailLogin, setIsCheckedEmailLogin] = useState<boolean>(false);
  const { current, transition } = useContext(AppContext);
  const {
    context: { email, lang, i18n, privacy },
  } = current;
  const checkedSocialLogin = isCheckedSocialLogin || (isCheckedSocialLogin === undefined && privacy);
  const checkedEmailLogin = isCheckedEmailLogin || (isCheckedEmailLogin === undefined && privacy);

  return (
    <AppContainer narrow center>
      <IntroWrapper>
        <SecondaryTitle>{i18n[lang].welcome}</SecondaryTitle>
        <Prose>{i18n[lang].choose_login_method}</Prose>
      </IntroWrapper>
      <ModalFixWidth>
        <ModalContentItem>
          {microsoftLoginStatus === 'error' && <Alert type="error">{message}</Alert>}
          <Active active={checkedSocialLogin}>
            <MicrosoftLoginButton
              onClick={async () => {
                try {
                  const microsoftLogin = await fb.auth().signInWithPopup(microsoftProvider);
                  await transition({ type: 'AUTHENTICATE', user: microsoftLogin.user });
                } catch (e) {
                  setMicrosoftLoginStatus('error');
                  setMessage(getErrorMessage({ key: 'error_while_microsoft_auth' }, lang, i18n));
                }
              }}
            >
              <MicrosoftLogo />
              <MicrosoftText>{i18n[lang].sign_in_with_microsoft}</MicrosoftText>
            </MicrosoftLoginButton>
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={fb.auth()} />
          </Active>
          <PrivacyWrapper>
            <Checkbox
              type="checkbox"
              value="privacy"
              checked={checkedSocialLogin}
              onChange={() => setIsCheckedSocialLogin(!checkedSocialLogin)}
              aria-label={i18n[lang].agree_to_privacy}
            />
            <Label>
              <a
                href="/"
                onClick={async e => {
                  e.preventDefault();
                  await transition('PRIVACY');
                }}
              >
                {i18n[lang].agree_to_privacy}
              </a>
            </Label>
          </PrivacyWrapper>
        </ModalContentItem>
      </ModalFixWidth>
      <SecondaryTitle>{i18n[lang].or}</SecondaryTitle>
      <ModalFixWidth>
        <ModalContentItem>
          <Prose variation={TypoVariations.secondary}>{i18n[lang].email_text}</Prose>
        </ModalContentItem>
        <Form
          onSubmit={async () => {
            try {
              const trimmedMail = email && email.trim();
              if (!trimmedMail || !isValidEmail(trimmedMail)) {
                throw new HrInfoError('error_email', `"${trimmedMail}".`);
              }
              if (!checkedEmailLogin) {
                throw new HrInfoError('error_you_need_to_agree', `"${trimmedMail}".`);
              } else {
                fb.auth().languageCode = lang;
                await fb.auth().sendSignInLinkToEmail(trimmedMail, {
                  url: process.env.REACT_APP_BASE_URL!,
                  handleCodeInApp: true,
                });
                await transition({ type: 'PROVIDE_MAIL_ADRESS' });
              }
            } catch (e) {
              setEmailLoginStatus('error');
              setMessage(getErrorMessage(e, lang, i18n));
            }
          }}
          submitLabel={i18n[lang].obtain_access}
          status={emailLoginStatus}
          message={message}
        >
          <Input
            type="text"
            placeholder={i18n[lang].email}
            emphasis
            value={email}
            onChangeText={email => transition('UPDATE_MAIL_ADDRESS', { data: email })}
            aria-label={i18n[lang].email}
            withRadius={{ left: true, right: true }}
          />
          <PrivacyWrapper>
            <Checkbox
              type="checkbox"
              value="privacy"
              checked={checkedEmailLogin}
              onChange={() => setIsCheckedEmailLogin(!checkedEmailLogin)}
              aria-label={i18n[lang].agree_to_privacy}
            />
            <Label>
              <a
                href="/"
                onClick={async e => {
                  e.preventDefault();
                  await transition('PRIVACY');
                }}
              >
                {i18n[lang].agree_to_privacy}
              </a>
            </Label>
          </PrivacyWrapper>
        </Form>
      </ModalFixWidth>
    </AppContainer>
  );
};

const IntroWrapper = styled.div`
  margin: ${({ theme: { spacing } }) => `${spacing.lg} ${spacing.xl}`};
  padding: ${({ theme: { spacing } }) => `${spacing.lg} 0`};
  width: 500px;
  max-width: 90vw;
`;

const Active = styled.div<{ active: boolean }>`
  ${({ active }) =>
    !active
      ? `
        cursor: not-allowed;
        pointer-events: none;
        `
      : ''}
`;

const MicrosoftLogo = styled.span`
  display: inline-block;
  background: url('/assets/favicon.png') no-repeat;
  background-size: 18px 18px;
  width: 18px;
  height: 18px;
  margin: -1px 17px 0 16px;
`;

const MicrosoftText = styled.span`
  vertical-align: center;
  display: inline-block;
  margin-bottom: 0;
`;

const MicrosoftLoginButton = styled.button`
  cursor: pointer;
  color: #757575;
  display: flex;
  margin: 0 auto;
  padding: 0;
  font-weight: 500;
  max-width: 220px;
  min-height: 40px;
  align-items: center;
  background: white;
  text-align: left;
  width: 100%;
  border: none;
  border-radius: 2px;
  min-width: 64px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  will-change: box-shadow;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

  :active {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
`;

const PrivacyWrapper = styled.div`
  display: flex;
  padding: ${({ theme: { spacing } }) => spacing.xl} 0;
`;

const Checkbox = styled.input`
  margin-right: ${({ theme: { spacing } }) => spacing.xxl};

  @media only screen and (max-width: 500px) {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }

  padding: 10px;
`;

const ModalFixWidth = styled(Modal)`
  width: 500px;
  max-width: 90vw;
`;
