import { HrInfoError } from './error';

const TIMEOUT = 10000;

export const promiseTimeout = <P>(promise: Promise<P>, ms: number = TIMEOUT): Promise<P> => {
  const timeout = new Promise((_resolve, reject) => {
    setTimeout(() => {
      reject(new HrInfoError('error_on_timeout'));
    }, ms);
  });

  return Promise.race([promise, timeout]) as Promise<P>;
};
