import Fuse from 'fuse.js';
import React, { FC, useContext } from 'react';

import { HRInfoItem, HRInfoItemProps } from '../components/hr-info-item';
import { NotFound } from '../components/not-found';
import { Joyride } from '../compositions/joyride';
import { withLoading } from '../containers/withLoading';
import { ScrollToTop } from '../elements/scroll-to-top';
import { AppContainer } from '../layout/app-layout';
import { List } from '../layout/list';
import { Context } from '../states/context';
import { AppContext } from '../utils/app-context';
import { Language } from '../utils/config';
import styled from '../utils/styled-components';

const HRInfoWithData: FC<Context> = ({ hr }) => {
  const { current } = useContext(AppContext);
  const {
    context: { searchTerm, lang, settings, categoryFilter },
  } = current;

  const categories = hr && consolidateCategories(hr, lang);
  let list = hr || [];
  let hasResults = true;

  if (categoryFilter) {
    list = list.filter(e => e.categories[lang].includes(categoryFilter));
  }

  if (searchTerm) {
    const fuse = new Fuse(list, {
      includeMatches: true,
      threshold: settings.search.threshold,
      location: settings.search.location,
      distance: settings.search.distance,
      minMatchCharLength: 3,
      keys: [
        {
          name: `title.${lang}` as any,
          weight: settings.search.titleWeigth,
        },
        {
          name: `search_terms.${lang}` as any,
          weight: settings.search.searchTermsWeigth,
        },
        {
          name: `short.${lang}` as any,
          weight: settings.search.shortWeigth,
        },
      ],
    });
    const resultWithIndices = fuse.search(searchTerm);

    list = resultWithIndices.map(res => {
      const title = res.item.title[lang];
      const short = res.item.short[lang];
      const results = { title, short };
      const matches =
        res.matches &&
        res.matches.length > 0 &&
        res.matches.filter((m: any) => m.key === `short.${lang}` || m.key === `title.${lang}`);

      if (matches && matches.length > 0) {
        matches.forEach((match: any) => {
          const key = match.key.split('.')[0];
          const indices = match.indices.reverse();
          const tag = ['<b>', '</b>'];

          if (indices) {
            for (const i of indices) {
              const matchLength = i[1] - i[0] + 3;
              if (searchTerm.length < matchLength && matchLength > 2) {
                for (let j = 1; j > -1; j--) {
                  results[key] = [results[key].slice(0, i[j] + j), tag[j], results[key].slice(i[j] + j)].join('');
                }
              }
            }
          }
        });
      }

      return {
        ...res.item,
        title: { ...res.item.title, [lang]: results.title },
        short: { ...res.item.short, [lang]: results.short },
      };
    });

    hasResults = list && list.length > 0;
  }

  return (
    <AppContainer withSearch filter={categories}>
      <Joyride />
      {hasResults ? (
        <>
          <List>
            {list.map(d => (
              <HRInfoItem {...d} key={d.id} />
            ))}
            <ScrollToTop />
          </List>
          <ListFooter>
            <NotFound />
          </ListFooter>
        </>
      ) : (
        <NotFound />
      )}
    </AppContainer>
  );
};

function consolidateCategories(hrItems: HRInfoItemProps[], lang: Language): string[] {
  return hrItems.reduce((cats: string[], { categories }: HRInfoItemProps) => {
    let consolidatedCategories = [...cats];
    if (categories) {
      for (const cat of categories[lang]) {
        consolidatedCategories = consolidatedCategories.includes(cat)
          ? consolidatedCategories
          : [...consolidatedCategories, cat];
      }
    }
    return consolidatedCategories;
  }, []);
}

const ListFooter = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;

export const HRInfo = withLoading(HRInfoWithData, 'hr', 'Home');
