import { useMachine } from '@xstate/react';
import React from 'react';

import { Admin } from './pages/admin';
import { Contact } from './pages/contact';
import { Error } from './pages/error';
import { HRInfo } from './pages/hr-info';
import { LoadingContent, LoadingSettings } from './pages/loading';
import { Login } from './pages/login';
import { Privacy } from './pages/privacy';
import { ThankYou } from './pages/thankyou';
import { UserManagement } from './pages/user-managment';
import { stateMachine } from './states';
import { AppContext } from './utils/app-context';
import { HRAppThemeProvider } from './utils/styled-components';

const App = () => {
  const [current, transition] = useMachine(stateMachine);
  const { context, matches } = current;

  return (
    <AppContext.Provider value={{ current, transition }}>
      <HRAppThemeProvider colors={context.settings.theme.colors}>
        {matches({ Screen: 'FetchingI18nAndSettings' }) ? (
          <LoadingSettings />
        ) : matches({ Screen: 'Authenticating' }) ? (
          <LoadingContent />
        ) : matches({ Screen: 'ProvidingEmail' }) ? (
          <Login />
        ) : matches({ Screen: 'EmailProvided' }) ? (
          <ThankYou />
        ) : matches({ Screen: 'Home' }) ? (
          <HRInfo />
        ) : matches({ Screen: 'Admin' }) ? (
          <Admin />
        ) : matches({ Screen: 'Privacy' }) ? (
          <Privacy />
        ) : matches({ Screen: 'Contact' }) ? (
          <Contact />
        ) : matches({ Screen: 'UserManagement' }) ? (
          <UserManagement />
        ) : matches({ Screen: 'Error' }) ? (
          <Error />
        ) : (
          <div />
        )}
      </HRAppThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
