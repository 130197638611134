import { HRInfoItemProps } from '../components/hr-info-item';
import { Color, defaultColors } from '../identity/colors';
import { getBrowserLanguage, Language } from '../utils/config';
import { HrInfoError } from '../utils/error';
import { I18N } from '../utils/i18n';

const searchConfig = {
  threshold: 0.4,
  titleWeigth: 0.4,
  shortWeigth: 0.1,
  longWeigth: 0.1,
  searchTermsWeigth: 0.4,
  location: 0,
  distance: 8000,
};

export const defaultContext = {
  hr: undefined,
  users: undefined,
  email: localStorage.getItem('email') || '',
  privacy: localStorage.getItem('privacy') === 'true',
  searchTerm: '',
  categoryFilter: '',
  lang: getBrowserLanguage(),
  navigationOpen: false,
  navigationIsClosing: false,
  showJoyride: localStorage.getItem('showJoyride') !== 'false',
  settings: {
    search: searchConfig,
    theme: { colors: defaultColors },
    timeout: undefined,
  },
  openedItems: [],
  openItems: [],
  i18n: {
    en: {
      error_authentication: 'Authentication failed.',
      error_email: 'Please provide a valid email address:',
      error_on_import: 'Error while importing document',
      error_on_timeout: 'Getting data took too long.',
      error_no_data: 'No data found',
      error_message: 'An error occured',
      error_title: 'An error occured',
      error_reload: 'Click here to reload',
      error_user_not_set: 'User is not set for some weird reason.',
      error_while_importing: 'Error while importing document',
      error_while_setting_role: 'Error while setting role',
      error_you_need_to_agree: 'You need to agree to privacy policy.',
    },
  },
};

export type Context = {
  hr?: HRInfoItemProps[];
  users?: User[];
  i18n: I18N;
  settings: Settings;
  email?: string;
  error?: HrInfoError | Error;
  searchTerm?: string;
  categoryFilter?: string;
  user?: User;
  lang: Language;
  openedItems: string[];
  openItems: string[];
  navigationOpen: boolean;
  navigationIsClosing: boolean;
  privacy: boolean;
  showJoyride: boolean;
};

export enum Role {
  admin = 'admin',
  recruiter = 'recruiter',
  user = 'user',
  applicant = 'applicant',
}

export type User = {
  id?: string;
  uid: string;
  email?: string;
  created?: { seconds: number };
  role: Role;
};

export type RoleBasedTimeout = { [key in keyof typeof Role]: number };

export type SearchSettings = {
  threshold: number;
  titleWeigth: number;
  shortWeigth: number;
  searchTermsWeigth: number;
  location: number;
  distance: number;
};

export type Settings = {
  search: SearchSettings;
  theme: { colors: Color };
  timeout?: RoleBasedTimeout;
};

export type UserSettings = {
  role: Role;
  created: Date;
};
