export enum Language {
  de = 'de',
  fr = 'fr',
  en = 'en',
}
export const defaultLanguage = Language.de;

export const getBrowserLanguage = (): Language => {
  const storedLanguage = localStorage.getItem('lang');
  const languages = Object.keys(Language).join(')|(');

  if (storedLanguage && languages.includes(storedLanguage)) {
    return storedLanguage as Language;
  }

  const getLang = new RegExp(`^[(${languages})]{2}`, 'gi');
  const userLang = navigator.language.match(getLang);

  return ((userLang && userLang[0]) as Language) || defaultLanguage;
};

export const isValidEmail = (email: string) =>
  email.match(
    // REGEX from https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
    // eslint-disable-next-line
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
  );

export type LangString = { [key in Language]: string };

export type LangArrayString = { [key in Language]: string[] };
