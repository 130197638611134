import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
  faAccessibleIcon,
  faFacebook,
  faLinkedin,
  faSkype,
  faSlackHash,
  faWhatsapp,
} from '@fortawesome/free-brands-svg-icons';
import { faHandPointer } from '@fortawesome/free-regular-svg-icons';
import {
  faAddressBook,
  faAddressCard,
  faAmbulance,
  faAngry,
  faAward,
  faBaby,
  faBabyCarriage,
  faBalanceScale,
  faBandAid,
  faBars,
  faBed,
  faBicycle,
  faBirthdayCake,
  faBriefcase,
  faBriefcaseMedical,
  faBullhorn,
  faBus,
  faCalendarAlt,
  faCalendarCheck,
  faCar,
  faCarCrash,
  faChargingStation,
  faChevronDown,
  faChevronUp,
  faClock,
  faCocktail,
  faCoffee,
  faCreditCard,
  faDesktop,
  faDollarSign,
  faDownload,
  faDumbbell,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpenText,
  faEuroSign,
  faExclamation,
  faExclamationTriangle,
  faFileAlt,
  faFileInvoiceDollar,
  faFilePdf,
  faFileSignature,
  faFileUpload,
  faFire,
  faFirstAid,
  faFrownOpen,
  faGasPump,
  faGem,
  faGift,
  faGlobe,
  faGraduationCap,
  faGrin,
  faHandHoldingUsd,
  faHandPointUp,
  faHome,
  faHospital,
  faHourglassEnd,
  faIdBadge,
  faLaptop,
  faLevelUpAlt,
  faListAlt,
  faMapMarkerAlt,
  faMobileAlt,
  faMoneyBillAlt,
  faMoneyCheckAlt,
  faMoon,
  faPassport,
  faPhone,
  faPhoneSlash,
  faPiggyBank,
  faPlane,
  faQuestion,
  faRecycle,
  faRocket,
  faSearch,
  faSignInAlt,
  faSignOutAlt,
  faSkiingNordic,
  faSlidersH,
  faSubway,
  faSuitcase,
  faTasks,
  faTaxi,
  faTimes,
  faTrain,
  faTruckMoving,
  faTshirt,
  faUser,
  faUserFriends,
  faUsers,
  faUserShield,
  faUserTie,
  faUtensils,
  faVenusMars,
  faWheelchair,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';

import styled from '../utils/styled-components';
import { colors } from './colors';

const IconBox = styled.div<{ texticon?: boolean }>`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 ${({ texticon }) => (texticon ? '0.5rem' : '0')};
`;

const iconNameMap = {
  'accessible-icon': faAccessibleIcon,
  'address-book': faAddressBook,
  'address-card': faAddressCard,
  ambulance: faAmbulance,
  angry: faAngry,
  award: faAward,
  baby: faBaby,
  'baby-carriage': faBabyCarriage,
  'balance-scale': faBalanceScale,
  'band-aid': faBandAid,
  bars: faBars,
  bed: faBed,
  bicycle: faBicycle,
  'birthday-cake': faBirthdayCake,
  briefcase: faBriefcase,
  'briefcase-medical': faBriefcaseMedical,
  bullhorn: faBullhorn,
  bus: faBus,
  'calendar-alt': faCalendarAlt,
  'calendar-check': faCalendarCheck,
  car: faCar,
  'car-crash': faCarCrash,
  'charging-station': faChargingStation,
  'chevron-down': faChevronDown,
  'chevron-up': faChevronUp,
  clock: faClock,
  cocktail: faCocktail,
  coffee: faCoffee,
  'credit-card': faCreditCard,
  desktop: faDesktop,
  dollar: faDollarSign,
  download: faDownload,
  dumbbell: faDumbbell,
  'ellipsis-v': faEllipsisV,
  envelope: faEnvelope,
  'envelope-open-text': faEnvelopeOpenText,
  'euro-sign': faEuroSign,
  exclamation: faExclamation,
  'exclamation-triangle': faExclamationTriangle,
  facebook: faFacebook,
  'file-alt': faFileAlt,
  'file-invoice-dollar': faFileInvoiceDollar,
  'file-pdf': faFilePdf,
  'file-signature': faFileSignature,
  'file-upload': faFileUpload,
  fire: faFire,
  'first-aid': faFirstAid,
  'frown-open': faFrownOpen,
  'gas-pump': faGasPump,
  gem: faGem,
  gift: faGift,
  globe: faGlobe,
  'graduation-cap': faGraduationCap,
  grin: faGrin,
  'hand-holding-usd': faHandHoldingUsd,
  'hand-pointer': faHandPointer,
  'hand-point-up': faHandPointUp,
  home: faHome,
  'hourglass-end': faHourglassEnd,
  hospital: faHospital,
  'id-badge': faIdBadge,
  laptop: faLaptop,
  'level-up-alt': faLevelUpAlt,
  linkedin: faLinkedin,
  'list-alt': faListAlt,
  'map-marker-alt': faMapMarkerAlt,
  'mobile-alt': faMobileAlt,
  'money-bill-alt': faMoneyBillAlt,
  'money-check-alt': faMoneyCheckAlt,
  moon: faMoon,
  passport: faPassport,
  phone: faPhone,
  'phone-slash': faPhoneSlash,
  plane: faPlane,
  'piggy-bank': faPiggyBank,
  question: faQuestion,
  recycle: faRecycle,
  rocket: faRocket,
  search: faSearch,
  'sign-in-alt': faSignInAlt,
  'sign-out-alt': faSignOutAlt,
  'skiing-nordic': faSkiingNordic,
  skype: faSkype,
  'slack-hash': faSlackHash,
  'sliders-h': faSlidersH,
  subway: faSubway,
  suitcase: faSuitcase,
  tasks: faTasks,
  taxi: faTaxi,
  'truck-moving': faTruckMoving,
  times: faTimes,
  train: faTrain,
  tshirt: faTshirt,
  user: faUser,
  'user-friends': faUserFriends,
  'user-shield': faUserShield,
  'user-tie': faUsers,
  users: faUserTie,
  utensils: faUtensils,
  'venus-mars': faVenusMars,
  whatsapp: faWhatsapp,
  wheelchair: faWheelchair,
};

const iconSizeMap: { [key: string]: SizeProp } = {
  medium: 'lg',
  large: '2x',
  small: '1x',
};

const iconVariationMap = {
  brand: colors.primary.brandcolor,
  dark: colors.grey[25],
  white: colors.primary.white,
};

export type IconName = keyof typeof iconNameMap;

type IconProps = {
  name: IconName;
  variation?: string;
  size?: keyof typeof iconSizeMap;
  texticon?: boolean;
};

export const Icon: FC<IconProps> = ({ name, variation = 'bright', size = 'large', texticon }) => (
  <IconBox texticon={texticon}>
    <FontAwesomeIcon icon={iconNameMap[name]} color={iconVariationMap[variation]} size={iconSizeMap[size]} />
  </IconBox>
);

const TextIconElement = styled.span`
  margin-right: 0.5rem;
`;

export const TextIcon: FC<{ name: IconName }> = ({ name }) => (
  <TextIconElement>
    <FontAwesomeIcon icon={iconNameMap[name]} size="sm" />
  </TextIconElement>
);
