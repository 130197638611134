export type SpacingDefinitions = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  xxl: string;
  xxxl: string;
};

export const spacing: SpacingDefinitions = {
  sm: '2px',
  md: '4px',
  lg: '8px',
  xl: '0.75rem', // 12px
  xxl: '1.125rem', // 18px
  xxxl: '2.5rem', // 40px
};
