import { colors } from '../identity/colors';
import { spacing } from '../layout/spacing';

export const theme = {
  colors,
  spacing,
  contentWidth: {
    narrow: '500px',
    wide: '900px',
  },
  headerHeight: '54px',
};

export type Theme = typeof theme;
