import { fontDeclaration, TypoVariations } from '../identity/typography/typography';
import styled from '../utils/styled-components';

type LinkProps = {
  variation?: TypoVariations;
};

export const Link = styled.a<LinkProps>`
  ${fontDeclaration};
  cursor: pointer;
  margin: 0;
  text-decoration: none;
  color: inherit;
  display: block;
  padding: 0.3rem 0;
`;
