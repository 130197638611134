import React, { FC, useContext } from 'react';

import { Form, Status } from '../components/form';
import { withLoading } from '../containers/withLoading';
import { Input } from '../elements/input';
import { Prose } from '../identity/typography/prose';
import { SecondaryTitle } from '../identity/typography/title';
import { AppContainer } from '../layout/app-layout';
import { FlexListRowItem, ListRow } from '../layout/list';
import { Modal, ModalContentItem } from '../layout/modal';
import { Context, Role, RoleBasedTimeout, User } from '../states/context';
import { AppContext } from '../utils/app-context';
import { isValidEmail, Language } from '../utils/config';
import { getErrorMessage, HrInfoError } from '../utils/error';
import fb from '../utils/firebase';
import { I18N, Texts } from '../utils/i18n';
import styled from '../utils/styled-components';

export const UserManagementWithData: FC<Context> = ({ users }) => {
  const { current, transition } = useContext(AppContext);
  const {
    context: {
      i18n,
      lang,
      user,
      settings: { timeout },
    },
  } = current;
  return (
    <AppContainer>
      <>
        <AssignRole
          i18n={i18n}
          lang={lang}
          currentUserRole={user!.role}
          onSuccess={() => transition({ type: 'USER_MANAGEMENT' })}
        />
        {users && <UserList texts={i18n[lang]} users={users} currentUserRole={user!.role} timeout={timeout} />}
      </>
    </AppContainer>
  );
};

export const UserManagement = withLoading(UserManagementWithData, 'users', 'UserManagement');

const UserList: FC<{ users: User[]; texts: Texts; currentUserRole: Role; timeout?: RoleBasedTimeout }> = ({
  users,
  currentUserRole,
  texts: { user, role, expiration_date, all_applicants, all_users },
  timeout,
}) => (
  <Modal>
    <ModalContentItem>
      <SecondaryTitle>{currentUserRole === Role.recruiter ? all_applicants : all_users}</SecondaryTitle>
      <ListRow>
        <FlexListRowItem wide>
          {user} <>{currentUserRole === Role.admin && `(${role})`}</>
        </FlexListRowItem>
        <FlexListRowItem>{expiration_date}</FlexListRowItem>
      </ListRow>
      {users &&
        users.map(({ role, created, id }) => (
          <ListRow key={id}>
            <FlexListRowItem wide>
              <Prose>
                {id} {currentUserRole === Role.admin && `(${role})`}
              </Prose>
            </FlexListRowItem>
            <FlexListRowItem>
              <Prose>
                {created &&
                  timeout &&
                  role === Role.applicant &&
                  new Date(created.seconds * 1000 + timeout[role]).toLocaleDateString('de')}
              </Prose>
            </FlexListRowItem>
          </ListRow>
        ))}
    </ModalContentItem>
  </Modal>
);

type UserManagementProps = { i18n: I18N; lang: Language; currentUserRole: Role; onSuccess: () => void };
type UserManagementState = { status?: Status; message?: string };

class AssignRole extends React.Component<UserManagementProps, UserManagementState> {
  private email: HTMLInputElement | null = null;
  private role: HTMLSelectElement | null = null;

  constructor(props: UserManagementProps) {
    super(props);
    this.state = {};
  }
  public componentDidUpdate(): void {
    if (this.state.status === 'success') {
      this.props.onSuccess();
    }
  }

  public render(): JSX.Element {
    const { i18n, lang, currentUserRole } = this.props;
    const texts = i18n[lang];
    const { status, message } = this.state;
    const isAdmin = currentUserRole === Role.admin;
    const isRecruiter = currentUserRole === Role.recruiter;

    return (
      <Form
        title={texts.role_management}
        onSubmit={async () => {
          try {
            const email = this.email!.value.trim();
            const role = isRecruiter ? Role.applicant : this.role && this.role.value;

            if (role === texts.choose_role) {
              throw Error(`You need to choose a role.`);
            }

            if (!isValidEmail(email)) {
              throw Error(`There is something wrong with the mail adress "${email}".`);
            }

            this.setState({
              status: 'pending',
              message: `Storing Userdata`,
            });

            try {
              await fb
                .firestore()
                .collection('/settings/authentication/users')
                .doc(email)
                .set({
                  role,
                  created: new Date(),
                });
            } catch (e) {
              throw new HrInfoError('error_while_setting_role', e.message);
            }
            this.email!.value = '';
            if (this.role && texts.choose_role) {
              this.role.value = texts.choose_role;
            }
            this.setState({
              status: 'success',
              message: texts.data_stored,
            });
          } catch (e) {
            this.setState({
              status: 'error',
              message: getErrorMessage(e, lang, i18n),
            });
          }
        }}
        submitLabel={texts.role_management}
        status={status}
        message={message}
      >
        <ModalContentItem>
          <FormElementContainer>
            {isAdmin && (
              <select defaultValue={texts.choose_role} ref={role => (this.role = role)}>
                <option value={texts.choose_role} disabled hidden>
                  {texts.choose_role}
                </option>

                {Object.keys(Role).map(role => (
                  <option value={role} key={role}>
                    {role}
                  </option>
                ))}
              </select>
            )}
          </FormElementContainer>
          <FormElementContainer>
            <Input
              ref={email => (this.email = email)}
              placeholder={texts.email}
              aria-label={texts.email}
              withRadius={{ right: true, left: true }}
            />
          </FormElementContainer>
        </ModalContentItem>
      </Form>
    );
  }
}

const FormElementContainer = styled.div`
  max-width: 400px;
  padding-top: 1rem;
`;
