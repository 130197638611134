import React from 'react';
import { AnyEventObject, Event, EventData, SCXML, State } from 'xstate';

import { stateMachine } from '../states';
import { Context } from '../states/context';

export interface AppContextType {
  current: State<Context, AnyEventObject>;
  transition: (
    event: string | AnyEventObject | Event<AnyEventObject>[] | SCXML.Event<AnyEventObject>,
    payload?: EventData | undefined,
  ) => State<Context, AnyEventObject, any, any>;
}

const appContext: AppContextType = {
  current: stateMachine.initialState,
  transition: () => stateMachine.initialState,
};

export const AppContext = React.createContext<AppContextType>(appContext);
