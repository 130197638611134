import React, { FC } from 'react';

import { CenteredLoadingIndicator } from '../elements/loading-indicator';
import { AppContainer, EmptyAppContainer } from '../layout/app-layout';

export const LoadingContent: FC = () => (
  <AppContainer center>
    <CenteredLoadingIndicator />
  </AppContainer>
);

export const LoadingSettings: FC = () => (
  <EmptyAppContainer>
    <CenteredLoadingIndicator />
  </EmptyAppContainer>
);
