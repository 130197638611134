import React, { FC, useContext } from 'react';

import { Prose } from '../identity/typography/prose';
import { PageTitle } from '../identity/typography/title';
import { TypoVariations } from '../identity/typography/typography';
import { AppContainer } from '../layout/app-layout';
import { Modal, ModalContainer, ModalContentItem } from '../layout/modal';
import { AppContext } from '../utils/app-context';

export const ThankYou: FC = () => {
  const { current } = useContext(AppContext);
  const {
    context: { lang, i18n },
  } = current;
  return (
    <AppContainer narrow center>
      <ModalContainer>
        <Modal>
          <ModalContentItem>
            <PageTitle>{i18n[lang].email_sent}</PageTitle>
          </ModalContentItem>
          <ModalContentItem>
            <Prose variation={TypoVariations.secondary}>{i18n[lang].email_sent_text}</Prose>
          </ModalContentItem>
        </Modal>
      </ModalContainer>
    </AppContainer>
  );
};
