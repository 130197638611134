import React, { FC, useContext } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { Icon } from '../identity/icon';
import { Role } from '../states/context';
import { AppContext } from '../utils/app-context';
import { Language } from '../utils/config';
import styled from '../utils/styled-components';

const width = '280px';

const Center = styled.div`
  flex: 1;
  justify-content: center;
`;

const MainNavigation: FC = () => {
  const { current, transition } = useContext(AppContext);
  const {
    context: { lang, user, i18n, navigationOpen, navigationIsClosing },
  } = current;

  const languages = Object.keys(Language);

  return (
    <>
      <NavigationToggle onClick={() => transition('OPEN_NAVIGATION')} aria-label={i18n[lang].main_navigation}>
        <Center>
          <Icon name="ellipsis-v" size="medium" variation="dark" />
        </Center>
      </NavigationToggle>
      <BackgroundDarkener
        navigationOpen={navigationOpen}
        showNavigation={navigationOpen || navigationIsClosing}
        onClick={() => {
          /* This function is needed so the onOutsideClick works on mobile */
        }}
      >
        <OutsideClickHandler
          onOutsideClick={() => {
            navigationOpen && transition('CLOSE_NAVIGATION');
          }}
        >
          <MainNavigationContainer width={navigationOpen ? width : '0px'}>
            <Navigation>
              <NavigationBlock>
                <NavigationItem
                  onClick={() => {
                    transition('CLOSE_NAVIGATION');
                  }}
                >
                  <NavigationIcon>
                    <Icon name="times" size="small" />
                  </NavigationIcon>
                  <div>{i18n[lang].close_navigation}</div>
                </NavigationItem>
              </NavigationBlock>
              <NavigationBlock>
                <LanguageSelectorWrapper>
                  <NavigationIcon>
                    <Icon name="globe" size="small" />
                  </NavigationIcon>
                  {languages.map(l => (
                    <LanguageSelectorItem key={l} onClick={() => l !== lang && transition('SET_LANGUAGE', { data: l })}>
                      {l.toUpperCase()}
                    </LanguageSelectorItem>
                  ))}
                </LanguageSelectorWrapper>
              </NavigationBlock>

              {!user && (
                <NavigationBlock>
                  <NavigationItem onClick={() => transition('LOGIN')}>
                    <NavigationIcon>
                      <Icon name="sign-in-alt" size="small" />
                    </NavigationIcon>
                    {i18n[lang].obtain_access}
                  </NavigationItem>
                </NavigationBlock>
              )}
              {user && user.role && (
                <NavigationBlock>
                  <LogoutWrapper>
                    {i18n[lang].logged_in_as} {user.email}
                  </LogoutWrapper>
                  <NavigationItem onClick={() => transition('LOGOUT')}>
                    <NavigationIcon>
                      <Icon name="sign-out-alt" size="small" />
                    </NavigationIcon>
                    {i18n[lang].logout}
                  </NavigationItem>
                </NavigationBlock>
              )}

              <NavigationBlock>
                {user && user.role && (
                  <NavigationItem onClick={() => transition('HOME')}>
                    <NavigationIcon>
                      <Icon name="home" size="small" />
                    </NavigationIcon>
                    {i18n[lang].home}
                  </NavigationItem>
                )}
                <NavigationItem onClick={() => transition('CONTACT')}>
                  <NavigationIcon>
                    <Icon name="phone" size="small" />
                  </NavigationIcon>
                  {i18n[lang].contact}
                </NavigationItem>
                {user && user.role && (
                  <NavigationItem onClick={() => transition('START')}>
                    <NavigationIcon>
                      <Icon name="question" size="small" />
                    </NavigationIcon>
                    {i18n[lang].help}
                  </NavigationItem>
                )}
                {user && user.role === Role.admin && (
                  <>
                    <NavigationItem onClick={() => transition('USER_MANAGEMENT')}>
                      <NavigationIcon>
                        <Icon name="user-friends" size="small" />
                      </NavigationIcon>{' '}
                      {i18n[lang].user_management}
                    </NavigationItem>
                  </>
                )}
                {user && user.role === Role.recruiter && (
                  <>
                    <NavigationItem onClick={() => transition('USER_MANAGEMENT')}>
                      <NavigationIcon>
                        <Icon name="address-card" size="small" />
                      </NavigationIcon>
                      {i18n[lang].add_applicant}
                    </NavigationItem>
                  </>
                )}
              </NavigationBlock>

              <NavigationBlock>
                <NavigationItem onClick={() => transition('PRIVACY')}>
                  <NavigationIcon>
                    <Icon name="user-shield" size="small" />
                  </NavigationIcon>
                  {i18n[lang].privacy_title}
                </NavigationItem>
              </NavigationBlock>
              <NavigationBlock>
                {user && user.role === Role.admin && (
                  <NavigationItem onClick={() => transition('ADMIN')}>
                    <NavigationIcon>
                      <Icon name="file-upload" size="small" />
                    </NavigationIcon>
                    {i18n[lang].admin}
                  </NavigationItem>
                )}
              </NavigationBlock>
            </Navigation>
          </MainNavigationContainer>
        </OutsideClickHandler>
      </BackgroundDarkener>
    </>
  );
};

const BackgroundDarkener = styled.div<{ showNavigation: boolean; navigationOpen: boolean }>`
  z-index: 100;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  ${({ showNavigation }) => (!showNavigation ? `visibility: hidden;` : '')};
  background-color: rgba(0, 0, 0, ${({ navigationOpen }) => (!navigationOpen ? '0.01' : '0.5')});
  transition: background-color 300ms ease-in-out;
`;

const MainNavigationContainer = styled.div<{ width: string }>`
  z-index: 100;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  background: ${({ theme: { colors } }) => colors.primary.white};
  transition: width 350ms ease-in-out;
  width: ${({ width }) => `${width}`};
  overflow: hidden;
`;

const NavigationBlock = styled.div`
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.grey[50]};
`;

const Navigation = styled.div`
  width: ${width};
`;

const LanguageSelectorWrapper = styled.div`
  display: flex;
`;

const NavigationItem = styled.a`
  z-index: 101;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  margin: ${({ theme: { spacing } }) => spacing.xl} 0;
  padding: ${({ theme: { spacing } }) => spacing.xl} 0;
  color: ${({ theme: { colors } }) => colors.primary.black};
`;

const LanguageSelectorItem = styled(NavigationItem)`
  display: inline-block;
  padding: ${({ theme: { spacing } }) => spacing.xl} ${({ theme: { spacing } }) => spacing.xl}
    ${({ theme: { spacing } }) => spacing.xl} 0;
`;

const LogoutWrapper = styled.div`
  overflow: hidden;
  color: ${({ theme: { colors } }) => colors.grey[50]};
  margin: ${({ theme: { spacing } }) => `calc(${spacing.xl}*2) ${spacing.xxl} ${spacing.xl}`};
`;

const NavigationIcon = styled.div`
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavigationToggle = styled.a`
  cursor: pointer;
`;

export default MainNavigation;
