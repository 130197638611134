import React, { FC, useContext } from 'react';

import { SimplePage } from '../compositions/simple-page';
import { Prose } from '../identity/typography/prose';
import { TypoVariations } from '../identity/typography/typography';
import { AppContext } from '../utils/app-context';
import { sanitize } from '../utils/sanitize';

export const Privacy: FC = () => {
  const { current, transition } = useContext(AppContext);
  const {
    context: { lang, i18n, user },
  } = current;
  return (
    <SimplePage
      user={user}
      transition={transition}
      title={i18n[lang].privacy_title}
      closeNavigation={i18n[lang].close_navigation}
    >
      <Prose variation={TypoVariations.secondary}>
        <span dangerouslySetInnerHTML={{ __html: sanitize(i18n[lang].privacy_text) }} />
      </Prose>
    </SimplePage>
  );
};
