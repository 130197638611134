import React from 'react';

import styled from '../utils/styled-components';

export const LoadingIndicator = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid ${({ theme: { colors } }) => colors.brandcolor[100]};
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${({ theme: { colors } }) => colors.brandcolor[100]} transparent transparent transparent;

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export const CenteredLoadingIndicator = () => (
  <Centered>
    <LoadingIndicator />
  </Centered>
);
