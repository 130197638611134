import React, { FC, ReactNode } from 'react';

import { EmptyHeader, Header } from '../components/header';
import { Color } from '../identity/colors';
import { fontDeclaration } from '../identity/typography/typography';
import styled from '../utils/styled-components';

const App = styled.div`
  ${fontDeclaration}
  height: 100%;
`;

const Main = styled.main`
  display: flex;
  background: ${({ theme: { colors } }: { theme: { colors: Color } }) => colors.grey[25]};
  min-height: ${({ theme: { headerHeight } }) => `calc(100% - ${headerHeight});`};
`;

const MainContent = styled.div<{ narrow?: boolean; center?: boolean }>`
  width: 100%;
  max-width: ${({ theme: { contentWidth }, narrow }) => contentWidth[narrow ? 'narrow' : 'wide']};
  margin: ${({ center }) => (center ? 'auto' : '0 auto')};
  ${({ center }) => (center ? 'justify-content: center' : '')};
  ${({ center }) => (center ? 'align-items: center' : '')};
  display: flex;
  flex-direction: column;
`;

type AppContainerProps = {
  children: ReactNode;
  withSearch?: boolean;
  filter?: string[];
  className?: string;
  narrow?: boolean;
  center?: boolean;
};

export const AppContainer: FC<AppContainerProps> = ({ children, className, withSearch, narrow, center, filter }) => (
  <App className={className}>
    <Header withSearch={withSearch} filter={filter} />
    <Main>
      <MainContent narrow={narrow} center={center}>
        {children}
      </MainContent>
    </Main>
  </App>
);

export const EmptyAppContainer: FC = ({ children }) => (
  <App>
    <EmptyHeader />
    <Main>
      <MainContent center>{children}</MainContent>
    </Main>
  </App>
);
