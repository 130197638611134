import React, { FC, ReactNode } from 'react';

import { sanitize } from '../../utils/sanitize';
import styled from '../../utils/styled-components';
import { fontDeclaration, TypoVariations, TypoVariationsMap, TypoWeights } from './typography';

type ProseProps = {
  children: ReactNode;
  variation?: TypoVariations;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
};

type ProsePropsWithInnerHTML = {
  content: string;
  variation?: TypoVariations;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
};

const Text = styled.p<ProseProps>`
  ${fontDeclaration};
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: ${TypoWeights.regular};
  color: ${({ variation = TypoVariations.primary }) => TypoVariationsMap[variation]};
  display: block;
  margin: 0 0.5rem 0.5rem 0;
`;

export const Prose: FC<ProseProps> = ({ children, variation, as }) => (
  <Text as={as} variation={variation}>
    {children}
  </Text>
);

export const ProseWithInnerHTML: FC<ProsePropsWithInnerHTML> = ({ content, variation, as }) => (
  <Text as={as} variation={variation}>
    <span dangerouslySetInnerHTML={{ __html: sanitize(content) }} />
  </Text>
);
