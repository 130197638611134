import React, { FC, useContext } from 'react';

import { Form, Status } from '../components/form';
import { AppContainer } from '../layout/app-layout';
import { ModalContentItem } from '../layout/modal';
import { AppContext } from '../utils/app-context';
import { Language } from '../utils/config';
import { getErrorMessage, HrInfoError } from '../utils/error';
import fb from '../utils/firebase';
import { I18N } from '../utils/i18n';

export const Admin: FC = () => {
  const { current } = useContext(AppContext);

  const {
    context: { i18n, lang },
  } = current;
  return (
    <AppContainer>
      <Import i18n={i18n} lang={lang} />
    </AppContainer>
  );
};

type ImportProps = { i18n: I18N; lang: Language };
type ImportState = { status?: Status; message?: string };

class Import extends React.Component<ImportProps, ImportState> {
  private data: HTMLTextAreaElement | null = null;

  constructor(props: ImportProps) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { i18n, lang } = this.props;
    const texts = i18n[lang];
    const { status, message } = this.state;
    return (
      <Form
        title={texts.import_data}
        onSubmit={async () => {
          try {
            const dataString = this.data!.value;
            const data: any[] = Object.values(JSON.parse(dataString));

            for (let i = 0; i < data.length; i++) {
              const {
                document_id,
                title_de,
                title_en,
                title_fr,
                short_de,
                short_en,
                short_fr,
                priority = '0',
                icon = '',
                visibility = '',
                search_terms_de = '',
                search_terms_en = '',
                search_terms_fr = '',
                categories_de = '',
                categories_fr = '',
                categories_en = '',
                url_de = '',
                url_fr = '',
                url_en = '',
              } = data[i];

              this.setState({
                status: 'pending',
                message: `Storing document ${i + 1} of ${data.length}`,
              });

              try {
                const itemReference = fb
                  .firestore()
                  .collection('/hr')
                  .doc(document_id);

                await fb.firestore().runTransaction(async transaction => {
                  const item = await transaction.get(itemReference);
                  const newData: { [key: string]: any } = {
                    title_de,
                    title_en,
                    title_fr,
                    short_de,
                    short_en,
                    short_fr,
                    icon,
                    visibility,
                    search_terms_de,
                    search_terms_en,
                    search_terms_fr,
                    categories_de: split(categories_de),
                    categories_en: split(categories_en),
                    categories_fr: split(categories_fr),
                    priority: parseInt(priority, 10),
                    url_de: split(url_de),
                    url_en: split(url_en),
                    url_fr: split(url_fr),
                  };
                  if (item.exists) {
                    const currentData = item.data();
                    if (currentData && currentData.priority) {
                      newData.priority = currentData.priority;
                    }
                  }
                  await transaction.set(itemReference, { ...newData });
                });
              } catch (e) {
                throw new HrInfoError('error_while_importing', `${document_id}: ${e.message}`);
              }
            }
            this.setState({
              status: 'success',
              message: texts.data_stored,
            });
          } catch (e) {
            this.setState({
              status: 'error',
              message: getErrorMessage(e, lang, i18n),
            });
          }
        }}
        submitLabel={texts.import}
        status={status}
        message={message}
      >
        <ModalContentItem>
          <textarea ref={data => (this.data = data)} style={{ width: '100%', height: '20rem' }} />
        </ModalContentItem>
      </Form>
    );
  }
}

const split = (a: string): string[] => (a ? a.split(',').map(str => str.trim()) : []);
